.carousel-container {
  background: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  .carousel-screen {
    background: radial-gradient(388.67px at 49.66% 50%, rgba(255, 255, 255, 3e-5) 0%, rgba(0, 0, 0, 0.3) 100%), #4a90e2;
    // background-blend-mode: multiply, normal;
    padding: 15px 0px 15px 10px;
    @media #{$iphone-5-portrait} {
      padding: 0;
    }
    @media #{$iphone-4-portrait} {
      padding: 0;
    }
    a {
      text-decoration: none;
      display: block;
    }
    .slick-slider {
    }
    .slick-list {
      .slick-slide {
      }
    }
    .my-page.carousel-item {
      .carousel-view {
        background: #ffffff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border-radius: 12px;
        height: 225px;
        margin: 10px;
        display: -webkit-flex; //_ Safari _
        display: flex;
        overflow: hidden;
        flex-direction: column;
        -webkit-flex-direction: column;
        position: relative;
        text-align: start;
        @media #{$iphone-5-portrait} {
          height: 195px;
          margin: 10px 0 10px 10px;
        }
        @media #{$iphone-6-portrait} {
          height: 205px;
          margin: 15px 1px 16px 15px;
        }

        @media #{$iphone-6plus-portrait} {
          height: 205px;
          margin: 20px 1px 20px 20px;
        }
        @media #{$iphone-4-portrait} {
          height: 150px;
          margin: 10px 0 10px 10px;
        }
        @media #{$samsung-s6-portrait} {
          height: 190px;
          margin: 10px 0 10px 10px;
        }
        .item-content {
          box-sizing: border-box;
          display: flex;
          display: -webkit-flex; //_ Safari _
          flex-direction: row;
          -webkit-flex-direction: row;
          padding: 5px 10px;
          .item-column {
            flex: 1;
            position: relative;
          }
        }
        .item-content:nth-child(1) {
          height: 66px;
          width: 100%;
          padding: 10px;
          .thumbnail {
            width: 110px;
            height: 50px;
            position: absolute;
            bottom: -7px;
            overflow: hidden;
            display: flex;
            align-items: center;
            @media #{$iphone-5-portrait} {
              width: 100px;
            }
            @media #{$iphone-6-portrait} {
              width: 110px;
            }
            @media #{$iphone-6plus-portrait} {
              width: 110px;
            }
            @media #{$iphone-4-portrait} {
              width: 95px;
              bottom: -15px;
            }
          }
        }
        .item-content:nth-child(2) {
          height: 54px;
          width: 100%;
          background: #e94d8f;
          @media #{$iphone-5-portrait} {
          }

          @media #{$iphone-6-portrait} {
          }

          @media #{$motorola-nexus-6-portrait} {
          }

          @media #{$iphone-x-portrait} {
          }

          @media #{$iphone-6plus-portrait} {
          }

          @media #{$iphone-4-portrait} {
          }

          @media #{$samsung-s6-portrait} {
          }
          h2 {
            font-family: Proxima Nova Condensed;
            font-style: italic;
            font-weight: 900;
            font-size: 24px;
            letter-spacing: 0.516923px;
            color: #ffffff;
            line-height: 1;
            @media #{$iphone-5-portrait} {
              font-size: 18px;
            }
            @media #{$iphone-6-portrait} {
              font-size: 21px;
            }
            @media #{$iphone-x-portrait} {
              font-size: 21px;
            }
            @media #{$iphone-6plus-portrait} {
              font-size: 21px;
            }
            @media #{$iphone-4-portrait} {
              font-size: 18px;
            }
          }

          .thumbnail {
            width: 130px;
            height: 95px;
            position: absolute;
            bottom: -10px;
            overflow: hidden;
            background: #f5f5f5;
            display: flex;
            align-items: center;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            @media #{$iphone-5-portrait} {
              width: 120px;
              bottom: -5px;
            }
            @media #{$iphone-6-portrait} {
              width: 130px;
              bottom: -5px;
            }
            @media #{$iphone-x-portrait} {
              width: 130px;
              bottom: -5px;
            }
            @media #{$iphone-6plus-portrait} {
              width: 150px;
              bottom: -5px;
            }
            @media #{$iphone-4-portrait} {
              width: 108px;
              bottom: -24px;
            }
            @media #{$samsung-s6-portrait} {
              width: 108px;
              bottom: -5px;
              right: 5px;
            }
          }
        }
        .item-content:nth-child(3) {
          height: 105px;
          width: 100%;
          display: flow-root;
          position: relative;
          .item-description {
            $font-size: 10px;
            $line-height: 1.4;
            $lines-to-show: 3;
            font-family: ITC Avant Garde Gothic Std;
            font-style: normal;
            font-weight: 500;
            letter-spacing: 0.454545px;
            color: #5a5a5a;
            padding: 8px 0;
            /* autoprefixer: ignore next */
            -webkit-box-orient: vertical;
            display: block;
            display: -webkit-box;
            height: 33px; /* Fallback for non-webkit */
            margin: 0 auto;
            font-size: $font-size;
            line-height: $line-height;
            -webkit-line-clamp: $lines-to-show;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            text-align: start;
            @media #{$iphone-5-portrait} {
              $font-size: 10px;
              $line-height: 1.2;
              $lines-to-show: 3;
              height: 33px;
              font-size: $font-size;
              line-height: $line-height;
              -webkit-line-clamp: $lines-to-show;
              padding-bottom: 0;
              text-align: start;
            }

            @media #{$iphone-6-portrait} {
            }

            @media #{$motorola-nexus-6-portrait} {
            }

            @media #{$iphone-x-portrait} {
            }

            @media #{$iphone-6plus-portrait} {
            }

            @media #{$iphone-4-portrait} {
              $font-size: 10px;
              $line-height: 1.2;
              $lines-to-show: 1;
              height: 13px;
              font-size: $font-size;
              line-height: $line-height;
              -webkit-line-clamp: $lines-to-show;
              padding-bottom: 0;
            }
          }
          .item-date-expired {
            font-family: ITC Avant Garde Gothic Std;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 0.454545px;
            color: #5a5a5a;
            position: absolute;
            border-top: 1px solid #e2e2e2;
            padding: 10px 0;
            bottom: 0;
            width: 93%;
            box-sizing: border-box;

            .expire {
              display: initial;
              font-family: Proxima Nova Condensed;
              font-style: italic;
              font-weight: 900;
              font-size: 24px;
              line-height: 20px;
              letter-spacing: 0.75px;
              color: #c0a35e;

            }
          }
        }

        &.gold {
          .item-content:nth-child(2) {
            height: 54px;
            width: 100%;
            background: linear-gradient(359.91deg, #b29856 1.72%, #e7d7a1 24.2%, #b18f3e 99.58%);
            h2 {
              font-family: Proxima Nova Condensed;
              font-style: italic;
              font-weight: 900;
              font-size: 24px;
              line-height: 1;
              letter-spacing: 0.516923px;
              color: #ffffff;
              @media #{$iphone-5-portrait} {
                font-size: 18px;
              }
              @media #{$iphone-6-portrait} {
                font-size: 21px;
              }
              @media #{$iphone-x-portrait} {
                font-size: 21px;
              }
              @media #{$iphone-6plus-portrait} {
                font-size: 21px;
              }
              @media #{$iphone-4-portrait} {
                font-size: 18px;
              }
            }

            .thumbnail {
              width: 130px;
              height: 95px;
              position: absolute;
              bottom: -10px;
              overflow: hidden;
              background-size: cover;
              background-position: center center;
              background-repeat: no-repeat;
              @media #{$iphone-5-portrait} {
                width: 120px;
                bottom: -5px;
              }
              @media #{$iphone-6-portrait} {
                width: 130px;
                bottom: -5px;
              }
              @media #{$iphone-x-portrait} {
                width: 130px;
                bottom: -5px;
              }
              @media #{$iphone-6plus-portrait} {
                width: 150px;
                bottom: -5px;
              }
              @media #{$iphone-4-portrait} {
                width: 108px;
                bottom: -24px;
              }
              @media #{$samsung-s6-portrait} {
                width: 108px;
                bottom: -5px;
                right: 5px;
              }
            }
          }
          .item-content:nth-child(3) {
            .item-description {
              text-align: start;
            }
            .item-date-expired {
              .expire {
                font-family: Proxima Nova Condensed;
                font-style: italic;
                font-weight: 900;
                font-size: 24px;
                line-height: 20px;
                letter-spacing: 0.75px;
                color: #c0a35e;
                @media #{$iphone-4-portrait} {
                  font-size: 20px;
                }
              }
            }
          }
        }
        &.green {
          .item-content:nth-child(2) {
            height: 54px;
            width: 100%;
            background: linear-gradient(179.91deg, #c7d319 0.42%, #a5af10 98.28%);
            h2 {
              font-family: Proxima Nova Condensed;
              font-style: italic;
              font-weight: 900;
              font-size: 24px;
              line-height: 26px;
              letter-spacing: 0.516923px;
              color: #ffffff;
              @media #{$iphone-5-portrait} {
                font-size: 18px;
              }
              @media #{$iphone-6-portrait} {
                font-size: 21px;
              }
              @media #{$iphone-x-portrait} {
                font-size: 21px;
              }
              @media #{$iphone-6plus-portrait} {
                font-size: 21px;
              }
              @media #{$iphone-4-portrait} {
                font-size: 18px;
              }
            }

            .thumbnail {
              width: 130px;
              height: 95px;
              position: absolute;
              bottom: -10px;
              overflow: hidden;
              background-size: cover;
              background-position: center center;
              background-repeat: no-repeat;
              @media #{$iphone-5-portrait} {
                width: 120px;
                bottom: -5px;
              }
              @media #{$iphone-6-portrait} {
                width: 130px;
                bottom: -5px;
              }
              @media #{$iphone-x-portrait} {
                width: 130px;
                bottom: -5px;
              }
              @media #{$iphone-6plus-portrait} {
                width: 150px;
                bottom: -5px;
              }
              @media #{$iphone-4-portrait} {
                width: 108px;
                bottom: -24px;
              }
              @media #{$samsung-s6-portrait} {
                width: 108px;
                bottom: -5px;
                right: 5px;
              }
            }
          }
          .item-content:nth-child(3) {
            .item-date-expired {
              .expire {
                font-family: Proxima Nova Condensed;
                font-style: italic;
                font-weight: 900;
                font-size: 24px;
                line-height: 20px;
                letter-spacing: 0.75px;
                color: #c0a35e;
                @media #{$iphone-4-portrait} {
                  font-size: 20px;
                }
              }
            }
          }
        }

        &.disabled {
          .item-content:nth-child(2) {
            background: #e2e2e2;
          }
          .item-content:nth-child(3) {
            z-index: 5;
            .item-date-expired {
              display: flex;
              & > div {
                display: flex;
                flex: 1;
                flex-direction: row;
              }
              .expire {
                font-family: Proxima Nova Condensed;
                font-style: italic;
                font-weight: 900;
                font-size: 24px;
                line-height: 20px;
                letter-spacing: 0.75px;
                color: #9b9b9b;
                flex: 1;
                @media #{$iphone-4-portrait} {
                  font-size: 20px;
                }
                &:nth-child(2) {
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-end;
                }
              }
              .btn-delete {
                margin: 0;
                padding: 0;
                font-family: ITC Avant Garde Gothic Std;
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 10px;
                letter-spacing: 0.357143px;
                text-transform: uppercase;
                color: #5a5a5a;
                svg {
                  margin-right: 5px;
                }
              }
            }
          }
          .overlay {
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            background: #fff;
            opacity: 0.5;
          }
        }
      }
    }
  }
}
