.list-page.main-page {
  .back-button {
    a {
      display: block;
      width: 50px;
      height: 50px;
    }
  }
  .icon-delete-header {
    position: fixed;
    top: 5px;
    right: 5px;
    z-index: 999;
    svg {
      path {
        fill: #ffffff;
      }
    }
  }
  .preloader-container {
    background: none;
    svg {
      circle {
        stroke: #c0a35e;
      } 
    }
  }
}

.list-screen {
  background: #ffffff;
  padding: 0 15px 0px 15px;
  position: relative;
  .list-header {
    padding: 20px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    h1 {
      $font-size: 24px;
      $line-height: 1.4;
      $lines-to-show: 2;
      font-family: Proxima Nova Condensed;
      font-style: italic;
      font-weight: 900;
      line-height: normal;
      letter-spacing: 0.75px;
      color: #4a4a4a;
      /* autoprefixer: ignore next */
      -webkit-box-orient: vertical;
      display: block;
      display: -webkit-box;
      height: i; /* Fallback for non-webkit */
      margin: 0 auto;
      font-size: $font-size;
      line-height: $line-height;
      -webkit-line-clamp: $lines-to-show;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      text-align: start;
    }

    span {
      font-family: ITC Avant Garde Gothic Std;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 21px;
      letter-spacing: 0.382353px;
      color: #4a4a4a;
    }
  }
  .add-item-to-list {
    padding-top: 0;
    .title-list {
      padding: 0;
    }
    li {
      & > div {
        padding-left: 0;
      }
    }
    .icon-delete {
      svg {
        path {
          fill: #9b9b9b;
        }
      }
    }
    
  }
  .btn-add {
    font-family: ITC Avant Garde Gothic Std;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 10px;
    letter-spacing: 0.46875px;
    color: #c0a35e;
    padding: 10px 0;
    text-transform: capitalize;
    background: #C0A35E;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
    border-radius: 60px;
    width: 60px;
    height: 60px;
    position: fixed;
    bottom: 15px;
    right: 20px;
    svg {
      width: 40px;
      height: 40px;
      path:nth-child(2) {
        fill: #ffffff;
      }
    }
  }
}
