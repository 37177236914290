.list-screen {
  background: #ffffff;
  padding: 0 15px 0px 15px;
  .btn-delete {
    font-family: ITC Avant Garde Gothic Std;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 10px;
    letter-spacing: 0.46875px;
    color: #c0a35e;
    padding: 10px 0;
    // text-transform: capitalize;
    text-transform: initial;
    svg {
      margin-right: 5px;
    }
  }
  .list-item-title {
    span {
      $font-size: 15px;
      $line-height: 1.4;
      $lines-to-show: 1;
      font-weight: 700;
      /* autoprefixer: ignore next */
      -webkit-box-orient: vertical;
      display: block;
      display: -webkit-box;
      height: i; /* Fallback for non-webkit */
      margin: 0 auto;
      font-size: $font-size;
      line-height: $line-height;
      -webkit-line-clamp: $lines-to-show;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      text-align: start;
    }
  }
}
