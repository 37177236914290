.carousel-container {
  &.public {
    background: radial-gradient(757.30px at 50% 50%, rgba(255, 255, 255, 0.0001) 0%, #000000 100%), #C7D319 !important;
  }
  h1 {
    font-family: "ITC Avant Garde Gothic Std";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.464286px;
    text-transform: uppercase;
    height: 43px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
}

.angebote.guest-container {
  .preloader-container{
    background: radial-gradient(388.67px at 49.66% 50%, rgba(255, 255, 255, 3e-05) 0%, rgba(0, 0, 0, 0.3) 100%), #4a90e2;
  }
}
