@font-face {
    font-family: 'ITC Avant Garde Gothic Std Demi';
    src: url('ITCAvantGardeStd-Demi.eot');
    src: url('ITCAvantGardeStd-Demi.eot?#iefix') format('embedded-opentype'),
        url('ITCAvantGardeStd-Demi.woff2') format('woff2'),
        url('ITCAvantGardeStd-Demi.woff') format('woff'),
        url('ITCAvantGardeStd-Demi.ttf') format('truetype'),
        url('ITCAvantGardeStd-Demi.svg#ITCAvantGardeStd-Demi') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ITC Avant Garde Gothic Std Book';
    src: url('ITCAvantGardeStd-Bk.eot');
    src: url('ITCAvantGardeStd-Bk.eot?#iefix') format('embedded-opentype'),
        url('ITCAvantGardeStd-Bk.woff2') format('woff2'),
        url('ITCAvantGardeStd-Bk.woff') format('woff'),
        url('ITCAvantGardeStd-Bk.ttf') format('truetype'),
        url('ITCAvantGardeStd-Bk.svg#ITCAvantGardeStd-Bk') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ITC Avant Garde Gothic Std';
    src: url('ITCAvantGardeStd-BoldObl.eot');
    src: url('ITCAvantGardeStd-BoldObl.eot?#iefix') format('embedded-opentype'),
        url('ITCAvantGardeStd-BoldObl.woff2') format('woff2'),
        url('ITCAvantGardeStd-BoldObl.woff') format('woff'),
        url('ITCAvantGardeStd-BoldObl.ttf') format('truetype'),
        url('ITCAvantGardeStd-BoldObl.svg#ITCAvantGardeStd-BoldObl') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'ITC Avant Garde Gothic Std';
    src: url('ITCAvantGardeStd-Md.eot');
    src: url('ITCAvantGardeStd-Md.eot?#iefix') format('embedded-opentype'),
        url('ITCAvantGardeStd-Md.woff2') format('woff2'),
        url('ITCAvantGardeStd-Md.woff') format('woff'),
        url('ITCAvantGardeStd-Md.ttf') format('truetype'),
        url('ITCAvantGardeStd-Md.svg#ITCAvantGardeStd-Md') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'ITC Avant Garde Gothic Std';
    src: url('ITCAvantGardeStd-BkObl.eot');
    src: url('ITCAvantGardeStd-BkObl.eot?#iefix') format('embedded-opentype'),
        url('ITCAvantGardeStd-BkObl.woff2') format('woff2'),
        url('ITCAvantGardeStd-BkObl.woff') format('woff'),
        url('ITCAvantGardeStd-BkObl.ttf') format('truetype'),
        url('ITCAvantGardeStd-BkObl.svg#ITCAvantGardeStd-BkObl') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'ITC Avant Garde Gothic Std';
    src: url('ITCAvantGardeStd-MdObl.eot');
    src: url('ITCAvantGardeStd-MdObl.eot?#iefix') format('embedded-opentype'),
        url('ITCAvantGardeStd-MdObl.woff2') format('woff2'),
        url('ITCAvantGardeStd-MdObl.woff') format('woff'),
        url('ITCAvantGardeStd-MdObl.ttf') format('truetype'),
        url('ITCAvantGardeStd-MdObl.svg#ITCAvantGardeStd-MdObl') format('svg');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'ITC Avant Garde Gothic Std';
    src: url('ITCAvantGardeStd-Bold.eot');
    src: url('ITCAvantGardeStd-Bold.eot?#iefix') format('embedded-opentype'),
        url('ITCAvantGardeStd-Bold.woff2') format('woff2'),
        url('ITCAvantGardeStd-Bold.woff') format('woff'),
        url('ITCAvantGardeStd-Bold.ttf') format('truetype'),
        url('ITCAvantGardeStd-Bold.svg#ITCAvantGardeStd-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'ITC Avant Garde Gothic Std';
    src: url('ITCAvantGardeStd-XLtObl.eot');
    src: url('ITCAvantGardeStd-XLtObl.eot?#iefix') format('embedded-opentype'),
        url('ITCAvantGardeStd-XLtObl.woff2') format('woff2'),
        url('ITCAvantGardeStd-XLtObl.woff') format('woff'),
        url('ITCAvantGardeStd-XLtObl.ttf') format('truetype'),
        url('ITCAvantGardeStd-XLtObl.svg#ITCAvantGardeStd-XLtObl') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'ITC Avant Garde Gothic Std Extra';
    src: url('ITCAvantGardeStd-XLt.eot');
    src: url('ITCAvantGardeStd-XLt.eot?#iefix') format('embedded-opentype'),
        url('ITCAvantGardeStd-XLt.woff2') format('woff2'),
        url('ITCAvantGardeStd-XLt.woff') format('woff'),
        url('ITCAvantGardeStd-XLt.ttf') format('truetype'),
        url('ITCAvantGardeStd-XLt.svg#ITCAvantGardeStd-XLt') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'ITC Avant Garde Gothic Std';
    src: url('ITCAvantGardeStd-DemiObl.eot');
    src: url('ITCAvantGardeStd-DemiObl.eot?#iefix') format('embedded-opentype'),
        url('ITCAvantGardeStd-DemiObl.woff2') format('woff2'),
        url('ITCAvantGardeStd-DemiObl.woff') format('woff'),
        url('ITCAvantGardeStd-DemiObl.ttf') format('truetype'),
        url('ITCAvantGardeStd-DemiObl.svg#ITCAvantGardeStd-DemiObl') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Proxima Nova Condensed';
    src: url('ProximaNovaCond-BlackIt.eot');
    src: url('ProximaNovaCond-BlackIt.eot?#iefix') format('embedded-opentype'),
        url('ProximaNovaCond-BlackIt.woff2') format('woff2'),
        url('ProximaNovaCond-BlackIt.woff') format('woff'),
        url('ProximaNovaCond-BlackIt.ttf') format('truetype'),
        url('ProximaNovaCond-BlackIt.svg#ProximaNovaCond-BlackIt') format('svg');
    font-weight: 900;
    font-style: italic;
}
