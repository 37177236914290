.news-container {
  background: #fff;
  h1 {
    font-family: "ITC Avant Garde Gothic Std";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.464286px;
    text-transform: uppercase;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;    
  }
  .news-screen {
    .card {
      .card-media {
        .responsive-img {
          width: 100%;
          height: auto;
        }
      }
      .card-content {
        padding: 15px 15px 5px;
        .link {
          display: flex;
          h5 {
            font-size: 12px;
            color: #C0A35E;
            padding-bottom: 10px;
            word-break: break-word;
            text-decoration: underline;
            display: inline-block;
          }
          svg {
            width: 14px;
            height: 14px;
            margin-right: 4px;
            color: #C0A35E;
          }
        }
        .card-paragraph {
          font-size: 12px;
          color: #5A5A5A;
          line-height: 20px;
          word-break: break-word;
        }
      }
      .card-conten-guest {
        padding: 15px 15px 5px;
        .link {
          display: flex;
          h5 {
            font-size: 12px;
            color: #C7D319;
            padding-bottom: 10px;
            word-break: break-word;
            text-decoration: underline;
            display: inline-block;
          }
          svg {
            width: 14px;
            height: 14px;
            margin-right: 4px;
            color: #C7D319;
          }
        }
      }
      .card-action {
        padding: 0 15px 15px;
        a {
          font-size: 12px;
          color: #C7D319;
          text-decoration: none;
          position: relative;
          padding-left: 20px;
          svg {
            fill: #C7D319;
            width: 14px;
            position: absolute;
            left: 0;;
          }
        }
        .custome-btn {
          margin: 10px 0 !important;
        }
        .btn-all-news {
          margin: auto;
          border-radius: 20px;
          width: 100%;
          height: 35px;
          background-color: #C7D319;
          margin: 10px 0;
          span:first-child {
            font-family: Proxima Nova Condensed;
            font-style: italic;
            font-weight: 900;
            font-size: 15px;
            line-height: 10px;
            text-align: center;
            letter-spacing: 0.642857px;
            text-transform: uppercase;
            color: #fff;
            text-decoration: none;
            width: 100%;
            height: 25px;
            @media #{$iphone-4-portrait} {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
