.get-point-screen-outside {
  min-height: -webkit-fill-available;
  background: radial-gradient(1180.43px at 50% 50%, rgba(255, 255, 255, 0.0001) 0%, #000000 100%), #c0a35e;
  .get-point-gift-bg {
    min-height: -webkit-fill-available;
    padding: 160px 20px 0;
    display: flex;
    flex-direction: column;
    .image-coin {
      margin: 0 auto;
      width: 45%;
      text-align: center;
      @media #{$iphone-5-portrait} {
        margin: 0 auto;
        width: 45%;
      }

      @media #{$iphone-6-portrait} {
        margin: 0 auto;
        width: 50%;
      }

      @media #{$motorola-nexus-6-portrait} {
        margin: 0 auto;
        width: 60%;
      }

      @media #{$iphone-x-portrait} {
        margin: 0 auto;
        width: 60%;
      }

      @media #{$iphone-6plus-portrait} {
        margin: 0 auto;
        width: 60%;
      }

      @media #{$iphone-4-portrait} {
      }

      @media #{$iphone-4-landscape} {
      }

      @media #{$samsung-s6-portrait} {
        margin: 0 auto;
        width: 60%;
      }
      img {
      }
    }

    h1 {
      font-family: Proxima Nova Condensed;
      font-style: italic;
      font-weight: 900;
      font-size: 100px;
      line-height: 51px;
      text-align: center;
      color: #ffffff;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
      margin-bottom: 10px;
      @media #{$iphone-5-portrait} {
        margin-bottom: 25px;
      }

      @media #{$iphone-6-portrait} {
        margin-bottom: 25px;
      }

      @media #{$motorola-nexus-6-portrait} {
      }

      @media #{$iphone-x-portrait} {
      }

      @media #{$iphone-6plus-portrait} {
      }

      @media #{$iphone-4-portrait} {
      }

      @media #{$iphone-4-landscape} {
      }

      @media #{$samsung-s6-portrait} {
      }
    }
    h3 {
      font-family: Proxima Nova Condensed;
      font-style: italic;
      font-weight: 900;
      font-size: 36px;
      // line-height: 26px;
      text-align: center;
      letter-spacing: 0.8125px;
      color: #ffffff;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
      margin-bottom: 45px;
    }
    h6 {
      font-family: ITC Avant Garde Gothic Std;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 21px;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 45px;
      @media #{$iphone-5-portrait} {
        margin-bottom: 25px;
      }

      @media #{$iphone-6-portrait} {
        margin-bottom: 28px;
      }

      @media #{$motorola-nexus-6-portrait} {
        margin-bottom: 28px;
      }

      @media #{$iphone-x-portrait} {
        margin-bottom: 28px;
      }

      @media #{$iphone-6plus-portrait} {
        margin-bottom: 28px;
      }

      @media #{$iphone-4-portrait} {
      }

      @media #{$iphone-4-landscape} {
      }

      @media #{$samsung-s6-portrait} {
        margin-bottom: 28px;
      }
    }
    .btn-back {
      margin: 15px auto auto;
      border-radius: 20px;
      width: 80%;
      height: 35px;
      background-color: transparent;
      border: 1px solid #fff;
      box-shadow: none;
      span:first-child {
        font-family: "Proxima Nova Condensed";
        font-style: italic;
        font-weight: 900;
        font-size: 15px;
        line-height: 10px;
        text-align: center;
        letter-spacing: 0.642857px;
        text-transform: uppercase;
        color: #fff;
        text-decoration: none;
        width: 100%;
        height: 25px;
        @media #{$iphone-4-portrait} {
          font-size: 13px;
        }
        @media #{$iphone-5-portrait} {
          font-size: 13px;
        }
      }
    }
  }
  .btn-points {
    position: absolute;
    bottom: 13%;
    margin: auto;
    left: 0;
    right: 0;
    border-radius: 20px;
    width: 80%;
    height: 35px;
    background-color: #ffffff;
    span:first-child {
      font-family: Proxima Nova Condensed;
      font-style: italic;
      font-weight: 900;
      font-size: 15px;
      line-height: 10px;
      text-align: center;
      letter-spacing: 0.642857px;
      text-transform: uppercase;
      color: #000000;
      text-decoration: none;
      width: 100%;
      height: 25px;
      @media #{$iphone-4-portrait} {
        font-size: 13px;
      }
    }
  }
}
