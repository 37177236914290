.navigation-container {
  .bottom-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: #1e1e1e;
    @media #{$iphone-6-portrait} {
      height: 50px;
    }
    @media #{$iphone-x-portrait} {
      height: 70px;
    }
    @media #{$iphone-6plus-portrait} {
      height: 50px;
    }
    .tab-navigation {
      #shopping-list-icon {
        width: 0.9em;
        margin-bottom: 2px;
      }
      #percentage-icon,
      #shopping-list-icon,
      #home-icon,
      #point-icon {
        fill: currentColor;
        path {
          fill: currentColor;
        }
      }

      span {
        & > span {
          font-family: "ITC Avant Garde Gothic Std";
          font-style: normal;
          font-weight: 500;
          font-size: 9px;
          line-height: 10px;
          text-align: center;
          letter-spacing: 0.5px;
        }
      }
    }
  }
  .add-to-home-screen-ios {
    margin: 0 auto;
    border-radius: 10px;
    width: 90%;
    font-size: 12px;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    padding: 10px;
    box-sizing: border-box;
    position: fixed;
    left: 0;
    bottom: 20px;
    line-height: normal;
    right: 0;
    img.icon-add-new {
      width: 34px;
      margin-right: 10px;
      opacity: 0.5;
      filter: alpha(opacity=50);
    }
    img.icon-share-iphone {
      width: 16px;
      height: auto;
      margin-right: 10px;
      opacity: 0.5;
      filter: alpha(opacity=50);
      position: relative;
      left: -5px;
    }
    .button-close {
      position: absolute;
      display: flex;
      right: 0;
      top: 0;
      width: 25px;
      height: 25px;
      justify-content: center;
      align-items: center;
      font-family: "Helvetica", "Arial", sans-serif;
      &:after {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        content: "\2715";
        font-size: 13px;
        color: #000;
        line-height: 100px;
        text-align: center;
      }
    }
    div:nth-child(2) {
      position: relative;
      left: 40px;
      width: 85%;
      @media #{$iphone-6-portrait} {
        font-size: 12px;
        top: -2px;
      }

      @media #{$iphone-x-portrait} {
        font-size: 12px;
      }

      @media #{$iphone-6plus-portrait} {
        font-size: 12px;
      }
      span {
        float: left;
        display: flex;
        align-items: center;
        img {
          width: 5%;
          margin-left: 7px;
          margin-right: 7px;
        }
      }
    }
    div:nth-child(1) {
      position: absolute;
    }
  }
  .arrow_box:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: rgba(255, 255, 255, 1);
    border-width: 10px;
    margin-left: -10px;
  }
  .add-homescreen-container {
    width: 100%;
    font-size: 12px;
    background-color: white;
    display: flex;
    padding: 10px;
    box-sizing: border-box;
    position: fixed;
    height: 52px;
    left: 0;
    bottom: 0;
    border: 0;
    align-items: center;
    z-index: 0;
    .add-homescreen-button {
      width: 100%;
      font-size: 12px;
      background-color: rgba(255, 255, 255, 1);
      display: flex;
      padding: 10px;
      box-sizing: border-box;
      position: fixed;
      left: 0;
      bottom: 0;
      border: 0;
      align-items: center;
      img {
        width: 32px;
        margin-right: 10px;
      }
    }
    .button-close {
      position: absolute;
      float: right;
      width: 50px;
      height: 50px;
      line-height: 50px;
      right: 0;
      justify-content: center;
      display: flex;
      z-index: 1;
      &:after {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        content: "\2715";
        font-size: 13px;
        color: #000;
        text-align: center;
      }
    }
  }
}
