.gutschein-content {
  padding-top: 50px;
  img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
  .gutschein-message {
    padding: 0 5%;
    h1 {
      font-family: Proxima Nova Condensed;
      font-style: italic;
      font-weight: 900;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 0.75px;
      color: #C0A35E;
    }
    p, strong {
      font-family: ITC Avant Garde Gothic Std;
      font-style: normal;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.454545px;
      color: #5A5A5A;
    }
    p {
      font-weight: 500;
    }
    strong {
      display: block;
      font-weight: bold;
      padding-top: 5%;
    }
    .btn-gutschein {
      border-radius: 20px;
      width: 100%;
      height: 35px;
      margin-top: 10%;
      background-color: #c0a35e;
      span:first-child {
        font-family: Proxima Nova Condensed;
        font-style: italic;
        font-weight: 900;
        font-size: 15px;
        line-height: 10px;
        text-align: center;
        letter-spacing: 0.642857px;
        text-transform: uppercase;
        color: #fff;
        text-decoration: none;
        width: 100%;
        height: 25px;
        @media #{$iphone-4-portrait} {
          font-size: 13px;
        }
        @media #{$iphone-5-portrait} {
          font-size: 13px;
        }
      }
    }
  }
}
