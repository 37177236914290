.add-item.main-page {
  .back-button {
    a {
      width: 45px;
      height: 45px;
      display: block;
      &:before {
        content: "";
        display: inline-block;
        // background: url("../../assets/img/back-button-white.png") no-repeat;
        width: 9px;
        height: 15px;
        position: absolute;
        left: 4.53%;
        right: 93.07%;
        top: 35.4%;
        bottom: 95.05%;
      }
    }
  }
}

.add-item-container {
  .add-item-screen {
    form {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 50px;
      flex-direction: column;
      .outlined-bare {
        background: #f5f5f5;
        width: 80%;
        height: 40px;
        border-width: 0;
        padding: 10px;
        line-height:0;
        margin: auto;
        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          font-family: ITC Avant Garde Gothic Std;
          font-style: normal;
          font-weight: 500;
          line-height:40px;
          padding-top: 5px;
          height:40px;
          font-size: 13px;
          letter-spacing: 0.382353px;
          color: #9b9b9b;
          @media #{$iphone-5-portrait} {
            line-height:normal;
            padding-top: 5px;
            height:40px;
          }

          @media #{$iphone-6-portrait} {
            line-height:normal;
            padding-top: 5px;
            height:40px;
          }

          @media #{$motorola-nexus-6-portrait} {}

          @media #{$iphone-x-portrait} {
            line-height:normal;
            padding-top: 5px;
            height:40px;
          }

          @media #{$iphone-6plus-portrait} {
            line-height:normal;
            padding-top: 5px;
            height:40px;
          }

          @media #{$iphone-4-portrait} {}

          @media #{$iphone-4-landscape} {}

          @media #{$samsung-s6-portrait} {}
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          font-family: ITC Avant Garde Gothic Std;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 10px;
          letter-spacing: 0.382353px;
          color: #9b9b9b;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          font-family: ITC Avant Garde Gothic Std;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 10px;
          letter-spacing: 0.382353px;
          color: #9b9b9b;
        }
      }
      .btn-points {
        margin: 20px auto 0 auto;
        left: 0;
        right: 0;
        border-radius: 20px;
        width: 80%;
        height: 35px;
        background-color: #c0a35e;
        span:first-child {
          font-family: Proxima Nova Condensed;
          font-style: italic;
          font-weight: 900;
          font-size: 15px;
          line-height: 10px;
          text-align: center;
          letter-spacing: 0.642857px;
          text-transform: uppercase;
          color: #ffffff;
          text-decoration: none;
          width: 100%;
          height: 25px;
          @media #{$iphone-4-portrait} {
            font-size: 13px;
          }
        }
      }
    }
    *:focus {
      outline: none;
    }
  }
}
