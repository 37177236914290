.login-page {
  position: relative;
  text-align: center;
  height: 100%;
  width: 100%;
  form {
    height: 100%;
    .section-back {
      position: fixed;
      top: 0;
      z-index: 99;
      width: 100%;
      .back-button {
        border-radius: initial;
        width: 100%;
        height: 50px;
        background: #000000;
        span:first-child {
          position: relative;
          font-family: ITC Avant Garde Gothic Std;
          font-style: normal;
          font-weight: bold;
          font-size: 15px;
          line-height: 13px;
          text-align: center;
          letter-spacing: 0.441177px;
          text-transform: uppercase;
          color: #FFFFFF;
          width: 100%;
          height: 30px;
          margin: auto;
          svg {
            position: absolute;
            left: 0;
            width: 30px;
          }
        }
      }
    }
    
    .section-content {
      padding-top: 50px;
      position: relative;
      height: calc(100% - 50px);
      h2 {
        width: 100%;
        padding-top: 10%;
        font-size: 24px;
        font-family: Proxima Nova Condensed;
        font-style: italic;
        font-weight: 900;
        letter-spacing: 0.75px;
        color: #C0A35E;
        @media #{$iphone-6-portrait} {
          font-size: 26px;
        }
        @media #{$iphone-6plus-portrait} {
          font-size: 30px;
        }
      }
      p {
        width: 90%;
        margin: 0 auto;
        margin-top: 5%;
        font-weight: 500;
        letter-spacing: 0.3px;
        font-size: 13px;
        line-height: 20px;
        font-family: ITC Avant Garde Gothic Std;
        font-style: normal;
        color: #5A5A5A;
        @media #{$iphone-6-portrait} {
          width: 86%;
        }
        @media #{$iphone-6plus-portrait} {
          width: 76%;
        }
      }
      
      .input-fields {
        width: 100%;
        margin-top: 10%;
        .input-id-login{
          width: 90%;
          height: 60px;
          margin: 0 auto;
          font-size: 13px;
          background: #f5f5f5;
          border: 0px solid;
          color: #000000;
          font-family: ITC Avant Garde Gothic Std;
          box-sizing: border-box;
          padding-left: 10px;
          padding-top: 5px;
        }
        .input-id-login-phone {
          width: 100%;
          height: 60px;
          font-size: 13px;
          background: #f5f5f5;
          border: 0px solid;
          border-radius: initial;
          color: #000000;
          font-family: ITC Avant Garde Gothic Std;
          box-sizing: border-box;
          padding-left: 10px;
          padding-top: 5px;
        }
        .input-id-login-phone-flag {
          border: initial;
        }
        .selected-flag {
          display: none;
        }
        .error {
          border: 1px solid;
          border-color: red;
        }
        .error-code {
          &.textColor {
            color: #C0A35E;
          }
          .preloader-container {
            min-height: initial;
            flex: initial;
            background: none;
            & > div {
              width: 16px !important;
              height: 16px !important;
              margin: 0;
            }
            svg {
              circle {
                stroke: #C0A35E;
              }
            }
          }
        }
        a {
          display: block;
          margin-top: 20px;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0.3px;
          border: 0px solid #ffffff;
          color: #C0A35E;
          font-style: normal;
          text-decoration: underline;
          background: transparent;
          font-family: ITC Avant Garde Gothic Std;
        }
        span {
          display: block;
          width: 100%;
          margin: 0 auto;
          font-size: 13px;
          margin-top: 10px;
          color: red;
          font-family: ITC Avant Garde Gothic Std;
        }
      }
      .field-email {
        margin-top: 10%;
      }
      .code-input {
        margin-top: 5%;
        p {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 20px;
          text-align: center;
          letter-spacing: 0.3px;
          color: #9B9B9B;
          margin-top: 10%;
          width: 70%;
        }
        span {
          display: block;
          width: 100%;
          margin: 0 auto;
          font-size: 13px;
          margin-top: 10px;
          color: red;
          font-family: ITC Avant Garde Gothic Std;
        }
        .resend-code {
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0.3px;
          color: #C0A35E;
          border: 0px solid #ffffff;
          text-decoration: underline;
          background: transparent;
          margin-top: initial;
        }
      }
      .submits {
        padding: 22% 0;
        .account-submit {
          width: 90%;
          left: 5%;
          top: 70%;
          display: block;
          margin: 0 auto;
          background: #C0A35E;
          box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
          border-radius: 60px;
          border: 0px solid;
          height: 35px;
          font-family: Proxima Nova Condensed;
          font-style: italic;
          font-weight: 900;
          font-size: 15px;
          line-height: 10px;
          text-align: center;
          letter-spacing: 0.642857px;
          text-transform: uppercase;
          color: #FFFFFF;
          &:disabled {
            box-shadow: none;
          }
        }
        @media #{$iphone-5-portrait} {}

        @media #{$iphone-6-portrait} {
          padding: 45% 0 10% 0;
        }
        @media #{$iphone-x-portrait} {
          padding: 63% 0 10% 0;
        }
        @media #{$iphone-6plus-portrait} {
          padding: 51% 0 10% 0;
        }
        @media #{$samsung-s6-portrait} {
          padding: 45% 0 10% 0;
        }
        &.submit-email {
          padding: calc(22% + 40px) 0;

          @media #{$iphone-5-portrait} {}
          @media #{$iphone-6-portrait} {
            padding: calc(45% + 40px) 0 10% 0;
          }
          @media #{$iphone-x-portrait} {
            padding: calc(63% + 40px) 0 10% 0;
          }
          @media #{$iphone-6plus-portrait} {
            padding: calc(51% + 40px) 0 10% 0;
          }
          @media #{$samsung-s6-portrait} {
            padding: calc(45% + 40px) 0 10% 0;
          }
        }
      }
      .submit-code-input {
        padding: 10% 0;
      }
      .login-verify {
        margin-top: 10%;
        input {
          box-sizing: border-box;
          width: 30%;
          height: 60px;
          font-family: ITC Avant Garde Gothic Std;
          font-weight: normal;
          font-size: 14px;
          background-color: #f5f5f5;
          color: #9B9B9B;
          border: 0px solid;
          margin-left: 4px;
          text-align: center;
        }
        input[type=number]::-webkit-inner-spin-button {  
          width: 20px;
          height: 30px;
          opacity: 0.7;
          margin-right: 10px;
          box-sizing: border-box;
          color: #9B9B9B;
        }
      }
    }
  }
}
