.welcome-container {
  position: relative;
  z-index: 0;
  height: 100%;
  background: radial-gradient(738.27px at 50% 50%, rgba(255, 255, 255, 0.0001) 0%, #000000 100%), #e8c060;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
  .welcome-screen {
    height: 100%;
    color: #ffffff;
    .welcome-header {
      width: 100%;
      font-family: "ITC Avant Garde Gothic Std";
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      text-align: center;
      letter-spacing: 0.441177px;
      text-transform: uppercase;
    }
    .welcome-body {
      margin-top: 153px;
      font-family: "Proxima Nova Condensed";
      font-style: italic;
      font-weight: 900;
      font-size: 2.25em;
      line-height: normal;
      text-align: center;
      letter-spacing: 0.947368px;
      @media #{$iphone-5-portrait} {
        margin-top: 50px;
      }

      @media #{$iphone-6-portrait} {
        margin-top: 30%;
      }
      @media #{$iphone-x-portrait} {
        margin-top: 35%;
      }
      @media #{$iphone-6plus-portrait} {
        margin-top: 30%;
      }

      @media #{$iphone-4-portrait} {
        margin-top: 55px;
      }
      @media #{$motorola-nexus-6-portrait} {
        margin-top: 35%;
      }
      p {
        margin: 0;
        &:nth-child(1) {
          font-size: 35px;
          @media #{$iphone-5-portrait} {
            font-size: 28px;
          }
          @media #{$iphone-6-portrait} {
            font-size: 35px;
          }
          @media #{$iphone-4-portrait} {
            font-size: 28px;
          }
        }
        &:nth-child(2) {
          font-weight: 900;
          font-size: 26px;
          @media #{$iphone-5-portrait} {
            font-size: 23px;
          }
          @media #{$iphone-4-portrait} {
            font-size: 23px;
          }
          @media #{$iphone-4-landscape} {
            font-size: 18px;
            width: 50%;
            margin: auto;
          }
        }
        &:nth-child(3) {
          font-family: "ITC Avant Garde Gothic Std";
          font-style: normal;
          font-weight: 500;
          font-size: 0.4em;

          @media #{$iphone-5-portrait} {
            font-family: "ITC Avant Garde Gothic Std";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.54px;
            margin-top: 10px;
          }
          @media #{$iphone-6-portrait} {
            margin-top: 25px;
            font-family: "ITC Avant Garde Gothic Std";
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.54px;
          }

          @media #{$motorola-nexus-6-portrait} {
          }

          @media #{$iphone-x-portrait} {
            font-family: "ITC Avant Garde Gothic Std";
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.54px;
          }

          @media #{$iphone-6plus-portrait} {
            font-family: "ITC Avant Garde Gothic Std";
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.54px;
          }

          @media #{$iphone-4-portrait} {
            font-size: 11px;
            margin-top: 15px;
          }

          @media #{$samsung-s6-portrait} {
            margin: 25px 0;
          }
        }
      }
    }
    .btn-welcome {
      margin: auto;
      border-radius: 20px;
      width: 100%;
      height: 35px;
      background-color: #ffffff;
      display: flex;
      margin-top: 30px;
      @media #{$iphone-5-portrait} {
        font-size: 12px;
      }

      @media #{$iphone-6-portrait} {
      }

      @media #{$iphone-x-portrait} {
      }

      @media #{$iphone-6plus-portrait} {
      }

      @media #{$iphone-4-portrait} {
      }

      @media #{$motorola-nexus-6-portrait} {
      }

      @media #{$iphone-4-landscape} {
      }

      span:first-child {
        font-family: Proxima Nova Condensed;
        font-style: italic;
        font-weight: 900;
        font-size: 15px;
        line-height: 10px;
        text-align: center;
        letter-spacing: 0.642857px;
        text-transform: uppercase;
        color: #000000;
        text-decoration: none;
        width: 100%;
        height: 25px;
        @media #{$iphone-5-portrait} {
          font-size: 12px;
        }
        @media #{$iphone-6-portrait} {
          font-size: 15px;
        }

        @media #{$iphone-6plus-portrait} {
          font-size: 15px;
        }

        @media #{$iphone-4-portrait} {
          font-size: 12px;
        }
      }
    }
  }
  .add-to-home-screen-ios {
    margin: auto;
    border-radius: 10px;
    width: 100%;
    font-size: 12px;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    bottom: 60px;
    line-height: normal;
    img.icon-add-new {
      width: 34px;
      margin-right: 10px;
      opacity: 0.5;
      filter: alpha(opacity=50);
    }
    img.icon-share-iphone {
      width: 16px;
      height: auto;
      margin-right: 10px;
      opacity: 0.5;
      filter: alpha(opacity=50);
      position: relative;
      left: -5px;
    }
    .button-close {
      position: absolute;
      display: flex;
      right: 0;
      top: 0;
      width: 25px;
      height: 25px;
      justify-content: center;
      align-items: center;
      font-family: "Helvetica", "Arial", sans-serif;
      &:after {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        content: "\2715";
        font-size: 13px;
        color: #000;
        line-height: 100px;
        text-align: center;
      }
    }
    div:nth-child(2) {
      position: relative;
      left: 40px;
      width: 85%;
      @media #{$iphone-6-portrait} {
        font-size: 11px;
        top: -2px;
      }

      @media #{$iphone-x-portrait} {
        font-size: 11px;
      }

      @media #{$iphone-6plus-portrait} {
        font-size: 12px;
      }
      span {
        float: left;
        display: flex;
        align-items: center;
        img {
          width: 5%;
          margin-left: 7px;
          margin-right: 7px;
        }
      }
    }
    div:nth-child(1) {
      position: absolute;
    }
  }
  .add-homescreen-container {
    width: 100%;
    font-size: 12px;
    background-color: white;
    display: flex;
    padding: 10px;
    box-sizing: border-box;
    position: fixed;
    height: 52px;
    left: 0;
    bottom: 0;
    border: 0;
    align-items: center;
    z-index: 0;
    .add-homescreen-button {
      width: 100%;
      font-size: 12px;
      background-color: rgba(255, 255, 255, 1);
      display: flex;
      padding: 10px;
      box-sizing: border-box;
      position: absolute;
      left: 0;
      bottom: 0;
      border: 0;
      align-items: center;
      img {
        width: 32px;
        margin-right: 10px;
      }
    }
    .button-close {
      position: absolute;
      float: right;
      width: 50px;
      height: 50px;
      line-height: 50px;
      right: 0;
      justify-content: center;
      display: flex;
      z-index: 1;
      &:after {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        content: "\2715";
        font-size: 13px;
        color: #000;
        text-align: center;
      }
    }
  }
 
  .arrow_box:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: rgba(255, 255, 255, 0.7);
    border-width: 10px;
    margin-left: -10px;
  }
  img {
    width: 10%;
    height: auto;
  }
  .add-to-home-banner {
    left: 0;
    box-sizing: border-box;
    .add-to-home-close-btn {
      font-size: 24px;
    }
    .add-to-home-content {
      .add-to-home-text {
        font-size: 13px;
        font-family: "ITC Avant Garde Gothic Std";
        font-style: normal;
        font-weight: 500;
      }
    }
  }
}
