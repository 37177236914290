.dialog-screen {
  background: transparent;
  top: 1px !important;
  #alert-dialog-title {
    margin-top: 15vh;
    padding: 0;
    h2 {
      font-family: Proxima Nova Condensed;
      font-style: italic;
      font-weight: 900;
      font-size: 34px;
      line-height: 40px;
      text-align: center;
      letter-spacing: 1px;
      color: #ffffff;
    }
  }
  .alert-dialog-description {
    &:first-child {
      font-family: Proxima Nova Condensed;
      font-style: italic;
      font-weight: 900;
      font-size: 26px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.75px;
      color: #ffffff;
      margin: 10px 0;
    }
    font-family: ITC Avant Garde Gothic Std;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.54px;
    color: #ffffff;
    .btn-sign-in {
      margin: 30px auto auto;
      border-radius: 20px;
      width: 100%;
      height: 35px;
      background-color: #c0a35e;

      span:first-child {
        font-family: Proxima Nova Condensed;
        font-style: italic;
        font-weight: 900;
        font-size: 15px;
        line-height: 10px;
        text-align: center;
        letter-spacing: 0.642857px;
        text-transform: uppercase;
        color: #fff;
        text-decoration: none;
        width: 100%;
        height: 25px;
        @media #{$iphone-4-portrait} {
          font-size: 13px;
        }
      }
    }
    .btn-deny {
      margin: 20px auto auto;
      border-radius: 20px;
      width: 100%;
      height: 35px;
      span:first-child {
        text-transform: initial;
        color: #fff;
        text-decoration: none;
        width: 100%;
        height: 25px;

        font-family: ITC Avant Garde Gothic Std;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.54px;
        @media #{$iphone-4-portrait} {
          font-size: 13px;
        }
      }
    }
  }

  & > div {
    & > div {
      background: transparent;
    }
  }
  .dialog-bg {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: -1;

    background-position: center center;
    background-image: url("../../assets/img/blur.jpg");
    background-size: 115%;
    // -webkit-filter: blur(10px);
    // filter: url("../../assets/img/blur.svg#blur");
    // filter: blur(10px);
  }
  .action-dialog {
    justify-content: center !important;
    span {
      color: #ffffff;
      font-family: ITC Avant Garde Gothic Std;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.54px;
    }
  }
}
