#pleaserotate-backdrop {
  background: radial-gradient(738.27px at 50% 50%, rgba(255, 255, 255, 0.0001) 0%, #000000 100%), #e8c060;
  overflow: hidden;
  top: 0px;
  left: 0px;
  position: fixed;
  width: 100%;
  height: 100%;
}
#pleaserotate-backdrop {
  z-index: 9000;
}
#pleaserotate-backdrop {
  cursor: pointer;
}

#pleaserotate-container {
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
#pleaserotate-graphic {
  margin-left: 50px;
  width: 200px;
  transform-origin: 50% 50%;
  animation: 2s ease 0s infinite normal none running pleaserotateframes;
}

#pleaserotate-message {
  position: absolute;
  bottom: 10%;
  width: 100%;
  font-family: Proxima Nova Condensed;
  font-style: italic;
  font-weight: 900;
  font-size: 15px;
  line-height: 10px;
  text-align: center;
  letter-spacing: 0.642857px;
  text-transform: uppercase;
  color: #ffffff;
}
