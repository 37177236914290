@import "variables.scss";
@import "../webfont/stylesheet.css";
@import "normalize.scss";
@import "../../components/NoMatch/nomatch.scss";
@import "../../app/App.scss";
@import "../../assets/scss/circle.scss";
@import "../../components/splashscreen/splashscreen.scss";
@import "../../components/splashscreen/progress/progress.scss";
@import "../../components/header/header.scss";
@import "../../components/welcome/welcome.scss";
@import "../../components/home/home.scss";
@import "../../components//Vorteile/vorteile.scss";
@import "../../components/loginPhone/login.scss";
@import "../../components/BottomNavigation/navigation.scss";
@import "../../components/Guest/guest.scss";
@import "../../components/Carousel/carousel.scss";
@import "../../components/News/news.scss";
@import "../../components/MyPage/mypage.scss";
@import "../../components/Points/points.scss";
@import "../../components/Angebote/angebote.scss";
@import "../../components/AngeboteGuest/angebote.scss";
@import "../../components/footer/footer.scss";
@import "../../components/Carousel/Item/item.scss";
@import "../../components/CarouselCoupon/carousel.scss";
@import "../../components/CarouselCoupon/Item/item.scss";
@import "../../components/CarouselCouponByView/carousel-by-view.scss";
@import "../../components/CarouselCouponByView/Item/item.scss";
@import "../../components/CarouselCouponPage/carousel.scss";
@import "../../components/CarouselCouponPage/Item/item.scss";
@import "../../components/register/register.scss";
@import "../../components/Voucher/voucher.scss";
@import "../../components/Dialog/dialog.scss";
@import "../../components//List/list.scss";
@import "../../components/List/ListItem/listItem.scss";
@import "../../components/Kategorie/kategorie.scss";
@import "../../components/More/more.scss";
@import "../../components/Profile/profile.scss";
@import "../../components/MorePoints/morepoints.scss";
@import "../../components/UploadProfile/upload-profile.scss";
@import "../../components/Gutschein/gutschein.scss";
@import "../../components/ShoppyPass/shoppy-pass.scss";
@import "../../components/Points/Congratulation/congratulation.scss";
@import "../../components/Points/CouponRegular/coupon-regular.scss";
@import "../../components/List/AddItem/add-item.scss";
@import "../../components/List/AddList/add-list.scss";
@import "../../components/List//AddArticleItem/add-article-item.scss";
@import "../../components/Points/GetPointRegular/getPointRegular.scss";
@import "../../components/Points/PassScanned/pass-scanned.scss";
@import "../../components/Preloader/preloader.scss";
@import "../../components/GuestCarouselCouponPage/Item/item.scss";
@import "../../components/Points/Coupon/Item/item.scss";
@import "../../components/Points/CouponLevelUp/Item/item.scss";
@import "../../components/Points//OutsideGeolocation/outsideGeolocation.scss";
@import "../../components/Points//OutsideGeolocationDenied/outsideGeolocationDenied.scss";
@import "../../app/rotate.scss";

@import "~slick-carousel/slick/slick.css";

*
{
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
-webkit-touch-callout: none;
-moz-touch-callout: none;
-ms-touch-callout: none;
touch-callout: none;
-webkit-user-drag: none;
-moz-user-drag: none;
-ms-user-drag: none;
user-drag: none;
}

input
{
-webkit-user-select: auto !important;
-khtml-user-select: auto !important;
-moz-user-select: auto !important;
-ms-user-select: auto !important;
user-select: auto !important;
}

html {
  -webkit-text-size-adjust: none;
	touch-action: manipulation;
}

html,
body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
}

body {
  margin: 0;
  min-height: 100%;
  font-family: "ITC Avant Garde Gothic Std";
  #root {
    height: 100%;
  }
}

.responsive-img {
  width: 100%;
  height: auto;
}

.add-to-home-screen {
  background: #fff;
  height: 40px;
  bottom: 0;
  position: absolute;
  width: 100%;
  display: none;
  justify-content: start;
  align-items: center;
  font-family: "ITC Avant Garde Gothic Std";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.54px;
  margin-top: 10px;
  padding: 5px 10px;
  img {
    width: 32px;
    margin-right: 7px;
  }
  span {
    position: absolute;
    float: right;
    width: 50px;
    height: 50px;
    line-height: 50px;
    right: 20px;
    &:after {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      content: "\2715";
      font-size: 13px;
      color: #000;
      text-align: center;
    }
  }
}
