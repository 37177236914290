body {
  height: 100%;
  .splash-screen-container {
    height: 100%;
    position: relative;
    background: radial-gradient(1100.17px at 50% 50%, rgba(255, 255, 255, 0.0001) 0%, #000000 100%), #ffffff;
  }
  .splash-screen {
    position: relative;
    height: 100%;
    // background: radial-gradient(1100.17px at 50% 50%, rgba(255, 255, 255, 0.0001) 0%, #000000 100%), #ffffff;
    // background-blend-mode: multiply, normal;
    .img-bird {
      width: 50%;
      height: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
