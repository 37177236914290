.main-page {
  .back-button {
    z-index: 999;
    a {
      display: none;
    }
  }
  .main-screen {
    padding: 50px 0;
    @media #{$iphone-5-portrait} {
      padding: 50px 0;
    }

    @media #{$iphone-6-portrait} {
      padding: 50px 0;
    }

    @media #{$motorola-nexus-6-portrait} {
      padding: 50px 0;
    }

    @media #{$iphone-x-portrait} {
      padding: 50px 0 70px 0;
    }

    @media #{$iphone-6plus-portrait} {
      padding: 50px 0;
    }

    @media #{$iphone-4-portrait} {
      padding: 50px 0;
    }

    @media #{$samsung-s6-portrait} {
      padding: 50px 0;
    }
    height: auto;
    min-height: 100%;
  }
}

.guest-container {
  overflow: hidden;
  .guest-screen {
    .section-1 {
      padding: 30px 20px 30px;
      background: radial-gradient(757.3px at 50% 50%, rgba(255, 255, 255, 0.0001) 0%, #000000 100%), #c7d319;
      // background-blend-mode: multiply, normal;
      font-family: ITC Avant Garde Gothic Std;
      color: #ffffff;
      h1 {
        font-family: Proxima Nova Condensed;
        font-style: italic;
        font-weight: 900;
        font-size: 26px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.8125px;
      }
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.2px;
        margin-top: 2%;
        strong {
          font-weight: 700;
        }
      }
      .btn-sign-in {
        margin: 30px auto auto;
        border-radius: 20px;
        width: 100%;
        height: 35px;
        background-color: #ffffff;
        span:first-child {
          font-family: Proxima Nova Condensed;
          font-style: italic;
          font-weight: 900;
          font-size: 15px;
          line-height: 10px;
          text-align: center;
          letter-spacing: 0.642857px;
          text-transform: uppercase;
          color: #000000;
          text-decoration: none;
          width: 100%;
          height: 25px;
          @media #{$iphone-4-portrait} {
            font-size: 13px;
          }
        }
      }

      .btn-sign-up {
        margin: 15px auto auto;
        border-radius: 20px;
        width: 100%;
        height: 35px;
        background-color: transparent;
        border: 1px solid #fff;
        box-shadow: none;
        span:first-child {
          font-family: "Proxima Nova Condensed";
          font-style: italic;
          font-weight: 900;
          font-size: 15px;
          line-height: 10px;
          text-align: center;
          letter-spacing: 0.642857px;
          text-transform: uppercase;
          color: #fff;
          text-decoration: none;
          width: 100%;
          height: 25px;
          @media #{$iphone-4-portrait} {
            font-size: 13px;
          }
          @media #{$iphone-5-portrait} {
            font-size: 13px;
          }
        }
      }
    }
    .section-2 {

    }
    .section-3 {
      
    }
  }
}
