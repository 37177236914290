@import "./progress.scss";

.main-page-profile {
  .back-button {
    z-index: 999;
    a {
      display: none;
    }
  }
  .main-screen {
    padding: 50px 0;
    height: auto;
    min-height: 100%;
    position: relative;
    @media #{$iphone-5-portrait} {
      padding: 50px 0;
    }

    @media #{$iphone-6-portrait} {
      padding: 50px 0;
    }

    @media #{$motorola-nexus-6-portrait} {
      padding: 50px 0;
    }

    @media #{$iphone-x-portrait} {
      padding: 50px 0 70px 0;
    }

    @media #{$iphone-6plus-portrait} {
      padding: 50px 0;
    }

    @media #{$iphone-4-portrait} {
      padding: 50px 0;
    }

    @media #{$samsung-s6-portrait} {
      padding: 50px 0;
    }
    @media #{$iphone-5-portrait} {
      padding: 50px 0;
    }

    @media #{$iphone-6-portrait} {
      padding: 50px 0;
    }

    @media #{$motorola-nexus-6-portrait} {
      padding: 50px 0;
    }

    @media #{$iphone-x-portrait} {
      padding: 50px 0 70px 0;
    }

    @media #{$iphone-6plus-portrait} {
      padding: 50px 0;
    }

    @media #{$iphone-4-portrait} {
      padding: 50px 0;
    }

    @media #{$samsung-s6-portrait} {
      padding: 50px 0;
    }
  }
  .preloader-container {
    width: 100%;
    z-index: 10;
    min-height: -webkit-fill-available;
  }
}

.profile-container {
  overflow: hidden;
  .profile-screen {
    .section-1 {
      padding: 30px 20px 30px;
      background: radial-gradient(1180.43px at 50% 50%, rgba(255, 255, 255, 0.0001) 0%, #000000 100%), #c0a35e;
      // background-blend-mode: multiply, normal;
      font-family: ITC Avant Garde Gothic Std;
      color: #ffffff;
      h1 {
        font-family: Proxima Nova Condensed;
        font-style: italic;
        font-weight: 900;
        font-size: 26px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.8125px;
      }
      .profile-img-container {
        height: 94px;
        width: 94px;
        margin: 0 auto;
        margin-top: 7%;
        margin-bottom: 7%;
        img {
          width: 100%;
          border-radius: 50%;
          height: 100%;
          border: 2px solid;
        }
      }
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.2px;
        text-transform: uppercase;
        color: #ffffff;
      }
    }
    .section-3 {
      .my-voucher {
        h1 {
          font-family: "ITC Avant Garde Gothic Std";
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 21px;
          text-align: center;
          letter-spacing: 0.464286px;
          text-transform: uppercase;
          height: 43px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .my-voucher-screen {
          background: radial-gradient(493.57px at 50% 50%, rgba(255, 255, 255, 0.0001) 0%, #000000 100%), #c0a301;
          background-blend-mode: multiply, normal;
          p {
            width: 55%;
            margin: 0 auto;
            padding: 10% 7%;
            font-family: ITC Avant Garde Gothic Std;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            letter-spacing: 0.411765px;
            color: rgba(255, 255, 255, 0.7);
          }
        }
      }
      .carousel-screen {
        background: radial-gradient(1180.43px at 50% 50%, rgba(255, 255, 255, 0.0001) 0%, #000000 100%), #c0a35e;
      }
    }
  }
}

.next-voucher-container {
  h1 {
    font-family: "ITC Avant Garde Gothic Std";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.464286px;
    text-transform: uppercase;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .next-voucher-screen {
    background: #f5f5f5;
    .saler {
      h1,
      h6 {
        padding: 25px 5%;
        text-align: center;
        color: #c0a35e;
        text-transform: initial;
        height: initial;
      }
      h1 {
        font-family: Proxima Nova Condensed;
        font-style: italic;
        font-weight: 900;
        line-height: 28px;
        font-size: 24px;
        letter-spacing: 0.75px;
      }
      h6 {
        font-family: ITC Avant Garde Gothic Std;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        font-size: 13px;
        letter-spacing: 0.71px;
        text-decoration: underline;
      }
      hr {
        border: 1px solid #e2e2e2;
        margin: 0;
        margin-bottom: 1px;
      }
      a {
        text-decoration: none;
      }
    }
    .progress {
      font-family: ITC Avant Garde Gothic Std;
      color: #9b9b9b;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 21px;
      .progress-content {
        padding: 5% 5%;
        position: relative;
        z-index: 0;
        .input-progress,
        .number-slider {
          position: relative;
          width: 82%;
          margin: 0 auto;
          margin-bottom: 2%;
          height: 14px;
          span {
            position: absolute;
            content: "";
            z-index: 2;
            display: block;
            width: 100%;
            height: 3px;
            margin: 0 auto;
            background: #c0a35e;
          }
          .slider {
            display: block;
            position: absolute;
            background-color: #fff;
            width: 100%;
            height: 3px;
            margin: 0 auto;
            z-index: 1;
          }
          .slider:after {
            content: "100";
            position: absolute;
            top: -9px;
            right: -26px;
          }
          .slider:before {
            content: "0";
            position: absolute;
            top: -9px;
            left: -14px;
          }
        }
        .number-slider {
          span {
            position: absolute;
            left: 0%;
            display: block;
            width: 20%;
            height: 44px;
            padding-top: 4px;
            font-family: ITC Avant Garde Gothic Std;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 21px;
            text-align: center;
            letter-spacing: 0.714286px;
            background: initial;
            color: #c0a35e;
          }
          span:after {
            content: "";
            position: absolute;
            top: 72%;
            left: 35%;
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 10px solid #c0a35e;
          }
        }
        p {
          width: 82%;
          margin: 0 auto;
          text-align: right;
        }
      }
      hr {
        border: 1px solid #e2e2e2;
        margin: 0;
        margin-bottom: 1px;
      }
    }
  }
}
