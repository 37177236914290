.main-page {
  width: 100%;
  .back-button {
    z-index: 999;
    a {
      display: none;
    }
  }
  .main-screen {
    padding: 50px 0;
    min-height: 100%;
    @media #{$iphone-5-portrait} {
      padding: 50px 0;
    }

    @media #{$iphone-6-portrait} {
      padding: 50px 0;
    }

    @media #{$motorola-nexus-6-portrait} {
      padding: 50px 0;
    }

    @media #{$iphone-x-portrait} {
      padding: 50px 0 70px 0;
    }

    @media #{$iphone-6plus-portrait} {
      padding: 50px 0;
    }

    @media #{$iphone-4-portrait} {
      padding: 50px 0;
    }

    @media #{$samsung-s6-portrait} {
      padding: 50px 0;
    }
  }
}

.point-container {
  overflow: hidden;
  min-height: -webkit-fill-available;
  display: flex;
  .preloader-container{
    min-height: initial;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(1180.43px at 50% 50%, rgba(255, 255, 255, 0.0001) 0%, #000000 100%), #C0A35E;
  }
  @import './Birthday/birthday.scss';
  @import './Coupon/coupon.scss';
  @import './GetPoints/getPoints.scss';
  @import './CouponRegular/coupon-regular.scss';
}
