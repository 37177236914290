.point-screen {
  min-height: -webkit-fill-available;
  background: radial-gradient(1180.43px at 50% 50%, rgba(255, 255, 255, 0.0001) 0%, #000000 100%), #c0a35e;
  background-image: url("../../assets/img/bg-birthday.svg");
  background-size: contain;
  width: 100%;
  .point-gift-bg {
    min-height: -webkit-fill-available;
    background-image: url("../../assets/img/birthday-gift.png");
    background-repeat: no-repeat;
    background-position: center 65%;
    padding-top: 60px;
    @media #{$iphone-5-portrait} {
      background-size: 35%;
      padding-top: 30px;
      background-position: center 70%;
    }

    @media #{$iphone-6-portrait} {
      background-size: auto;
      padding-top: 40px;
      background-position: center 70%;
      background-size: 50%;
    }

    @media #{$motorola-nexus-6-portrait} {
      background-size: auto;
      padding-top: 60px;
      background-position: center 65%;
    }

    @media #{$iphone-x-portrait} {
      background-size: auto;
      padding-top: 60px;
      background-position: center 65%;
    }

    @media #{$iphone-6plus-portrait} {
      background-size: auto;
      padding-top: 40px;
      background-position: center 70%;
      background-size: 50%;
    }

    @media #{$iphone-4-portrait} {
    }

    @media #{$iphone-4-landscape} {
    }

    @media #{$samsung-s6-portrait} {
      background-size: auto;
      padding-top: 60px;
      background-position: center 65%;
    }
    h1 {
      font-family: Proxima Nova Condensed;
      font-style: italic;
      font-weight: 900;
      font-size: 48px;
      line-height: 51px;
      text-align: center;
      color: #ffffff;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
      @media #{$iphone-5-portrait} {
        font-size: 32px;
        line-height: normal;
      }
      @media #{$iphone-6-portrait} {
        font-size: 48px;
        line-height: 51px;
        margin-bottom: 15px;
      }

      @media #{$motorola-nexus-6-portrait} {
        font-size: 48px;
        line-height: 51px;
        margin-bottom: 15px;
      }

      @media #{$iphone-x-portrait} {
        font-size: 48px;
        line-height: 51px;
        margin-bottom: 15px;
      }

      @media #{$iphone-6plus-portrait} {
        font-size: 48px;
        line-height: 51px;
        margin-bottom: 15px;
      }

      @media #{$iphone-4-portrait} {
      }

      @media #{$iphone-4-landscape} {
      }

      @media #{$samsung-s6-portrait} {
        font-size: 48px;
        line-height: 51px;
        margin-bottom: 15px;
      }
    }
    h5 {
      font-family: Proxima Nova Condensed;
      font-style: italic;
      font-weight: 900;
      font-size: 26px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 0.8125px;
      color: #ffffff;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
      @media #{$iphone-5-portrait} {
        font-size: 21px;
        line-height: normal;
      }
      @media #{$iphone-6-portrait} {
        font-size: 26px;
        line-height: 26px;
      }

      @media #{$motorola-nexus-6-portrait} {
        font-size: 26px;
        line-height: 26px;
      }

      @media #{$iphone-x-portrait} {
        font-size: 26px;
        line-height: 26px;
      }

      @media #{$iphone-6plus-portrait} {
        font-size: 26px;
        line-height: 26px;
      }

      @media #{$iphone-4-portrait} {
      }

      @media #{$iphone-4-landscape} {
      }

      @media #{$samsung-s6-portrait} {
        font-size: 26px;
        line-height: 26px;
      }
    }
  }
  .btn-points {
    position: absolute;
    bottom: 13%;
    margin: auto;
    left: 0;
    right: 0;
    border-radius: 20px;
    width: 80%;
    height: 35px;
    background-color: #ffffff;
    span:first-child {
      font-family: Proxima Nova Condensed;
      font-style: italic;
      font-weight: 900;
      font-size: 15px;
      line-height: 10px;
      text-align: center;
      letter-spacing: 0.642857px;
      text-transform: uppercase;
      color: #000000;
      text-decoration: none;
      width: 100%;
      height: 25px;
      @media #{$iphone-5-portrait} {
        font-size: 13px;
        bottom: 15%;
      }
      @media #{$iphone-4-portrait} {
        font-size: 13px;
      }
    }
  }
}
