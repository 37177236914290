.morepoints-container {
  overflow: hidden;
  .back-button {
    background-color: #f5f5f5;
    p {
      color: #000;
    }
    a {
      width: 45px;
      height: 45px;
      display: block;
      &:before {
        content: "";
        display: inline-block;
        background: url("../../assets/img/back-button-white.png") no-repeat;
        width: 9px;
        height: 15px;
        position: absolute;
        left: 4.53%;
        right: 93.07%;
        top: 35.4%;
        bottom: 95.05%;
      }
    }
  }
  .screen-container {
    padding: 70px 15px 15px;
    font-family: "Proxima Nova Condensed";
    h1 {
      font-size: 34px;
      line-height: 40px;
      letter-spacing: 0.894737px;
      font-style: italic;
      text-align: center;
      font-weight: 900;
    }
    h2 {
      font-weight: 900;
      font-size: 26px;
      line-height: 30px;
      letter-spacing: 0.75px;
      font-style: italic;
      text-align: center;
      font-weight: 900;
    }
    ul {
      color: #5a5a5a;
      padding-left: 16%;
      @media #{$iphone-6plus-portrait} {
        padding-left: 20%;
      }
      li {
        font-family: ITC Avant Garde Gothic Std;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.3px;
        margin: 25px 0;
        position:relative;
        strong {
          font-weight: 700;
        }
        .item-prefix {
          display: inline-block;
          font-family: ITC Avant Garde Gothic Std;
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: -0.8px;
          left: -16%;
          top: 0;
          padding-top: 2%;
          border: 2px solid #C0A35E;
          border-radius: 50%;
          width: 33px;
          height: 33px;
          text-align: center;
          position: absolute;
          color: #C0A35E;
          box-sizing: border-box;
        }
        .ind {
          border-color: #5A5A5A;
          color: #5A5A5A;
        }
      }
      li:first-child, li:nth-child(2) {
        strong {
          color: #C0A35E;
        }
      }
      // li::after {
      //   content: '';
      //   display: inline-block;
      //   position: absolute;
      //   top: 10%;
      //   left: -18%;
      //   width: 36px;
      //   height: 36px;
      //   background-repeat: no-repeat;
      // }
      // li:first-child::after {
      //   background-image: url('../../assets/img/list-style-1.jpg');
      // }
      // li:nth-child(2)::after {
      //   background-image: url('../../assets/img/list-style-5.jpg');
      // }
      // li:nth-child(3)::after, li:nth-child(4)::after {
      //   background-image: url('../../assets/img/list-style-ind.jpg');
      // }
    }
    .btn-more-points {
      border-radius: 20px;
      width: 100%;
      height: 35px;
      background-color: #c0a35e;
      span:first-child {
        font-family: Proxima Nova Condensed;
        font-style: italic;
        font-weight: 900;
        font-size: 15px;
        line-height: 10px;
        text-align: center;
        letter-spacing: 0.642857px;
        text-transform: uppercase;
        color: #fff;
        text-decoration: none;
        width: 100%;
        height: 25px;
        @media #{$iphone-4-portrait} {
          font-size: 13px;
        }
        @media #{$iphone-5-portrait} {
          font-size: 13px;
        }
      }
    }
  }
}
