.voucher.main-page {
  .back-button {
    z-index: 999;
    a {
      display: block;
      width: 40px;
      height: 50px;
    }
  }
  .main-screen {
    padding: 50px 0;
    @media #{$iphone-5-portrait} {
      padding: 50px 0;
    }

    @media #{$iphone-6-portrait} {
      padding: 50px 0;
    }

    @media #{$motorola-nexus-6-portrait} {
      padding: 50px 0;
    }

    @media #{$iphone-x-portrait} {
      padding: 50px 0 70px 0;
    }

    @media #{$iphone-6plus-portrait} {
      padding: 50px 0;
    }

    @media #{$iphone-4-portrait} {
      padding: 50px 0;
    }

    @media #{$samsung-s6-portrait} {
      padding: 50px 0;
    }
    .preloader-container {
      background: #fff;
      svg {
        circle {
          stroke: #c0a35e;
        } 
      }
    }
  }

  .voucher-container {
    overflow: hidden;
    padding: 30px 0;
    .voucher-screen {
      .section-1 {
        .voucher-photo {
          width: 220px;
          margin: 0 auto 30px auto;
        }

        .content {
          margin-top: 15px;
          padding: 0 20px;
          .content-inside {
            font-family: ITC Avant Garde Gothic Std;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 0.454545px;
            color: #5A5A5A;
          }
          h1 {
            font-family: Proxima Nova Condensed;
            font-style: italic;
            font-weight: 900;
            font-size: 24px;
            line-height: 28px;
            letter-spacing: 0.75px;
            color: #c0a35e;
            margin-bottom: 15px;
          }
          p {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: #5A5A5A;
            strong {
              font-weight: 700;
            }
          }
          .voucher-date-expired {
            font-family: ITC Avant Garde Gothic Std;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 0.454545px;
            color: #5a5a5a;
            margin: 15px 0;
          }
        }

        .barcode {
          display: flex;
          flex-direction: row;
          justify-content: center;
          img {
            width: 70%;
          }
        }
        .time-expired {
          height: 75px;
          background: #f5f5f5;
          font-family: Proxima Nova Condensed;
          font-style: italic;
          font-weight: 900;
          font-size: 36px;
          line-height: 28px;
          text-align: center;
          letter-spacing: 1.125px;
          color: #c0a35e;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
        }

        .btn-sign-in {
          margin: 30px auto auto;
          border-radius: 20px;
          width: 100%;
          height: 35px;
          background-color: #c0a35e;

          span:first-child {
            font-family: Proxima Nova Condensed;
            font-style: italic;
            font-weight: 900;
            font-size: 15px;
            line-height: 10px;
            text-align: center;
            letter-spacing: 0.642857px;
            text-transform: uppercase;
            color: #fff;
            text-decoration: none;
            width: 100%;
            height: 25px;
            @media #{$iphone-4-portrait} {
              font-size: 13px;
            }
          }
        }

        .btn-sign-up {
          margin: 15px auto auto;
          border-radius: 20px;
          width: 100%;
          height: 35px;
          background-color: transparent;
          border: 1px solid #fff;
          box-shadow: none;
          span:first-child {
            font-family: "Proxima Nova Condensed";
            font-style: italic;
            font-weight: 900;
            font-size: 15px;
            line-height: 10px;
            text-align: center;
            letter-spacing: 0.642857px;
            text-transform: uppercase;
            color: #fff;
            text-decoration: none;
            width: 100%;
            height: 25px;
            @media #{$iphone-4-portrait} {
              font-size: 13px;
            }
            @media #{$iphone-5-portrait} {
              font-size: 13px;
            }
          }
        }
      }
      .section-2 {
      }
      .section-3 {
      }
    }
  }
}
