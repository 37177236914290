.coupon-regular-screen {
  min-height: -webkit-fill-available;
  background: radial-gradient(1180.43px at 50% 50%, rgba(255, 255, 255, 0.0001) 0%, #000000 100%), #c0a35e;
  background-image: none;
  background-size: contain;
  .coupon-regular-gift-bg {
    min-height: -webkit-fill-available;
    background-image: initial;
    a {
      text-decoration: none;
      display: block;
    }
   @import './Item/item.scss';
   @import '../GetPointRegular/getPointRegular.scss';
  }
  .btn-points {
    position: absolute;
    bottom: 13%;
    margin: auto;
    left: 0;
    right: 0;
    border-radius: 20px;
    width: 80%;
    height: 35px;
    background-color: #ffffff;
    span:first-child {
      font-family: Proxima Nova Condensed;
      font-style: italic;
      font-weight: 900;
      font-size: 15px;
      line-height: 10px;
      text-align: center;
      letter-spacing: 0.642857px;
      text-transform: uppercase;
      color: #000000;
      text-decoration: none;
      width: 100%;
      height: 25px;
      @media #{$iphone-4-portrait} {
        font-size: 13px;
      }
    }
  }
}