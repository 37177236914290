.upload-profile-container {
  .section-back {
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    .back-button {
      border-radius: initial;
      width: 100%;
      height: 50px;
      background: #000000;
      box-shadow: initial;
      span:first-child {
        position: relative;
        font-family: ITC Avant Garde Gothic Std;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 13px;
        text-align: center;
        letter-spacing: 0.441177px;
        text-transform: uppercase;
        color: #FFFFFF;
        width: 100%;
        height: 30px;
        margin: auto;
        svg {
          position: absolute;
          left: 0;
          width: 30px;
        }
      }
    }
  }
  .upload-content {
    padding: 70px 15px 15px;
    .section-1 {
      padding: 12px 20px 30px;
      .upload-title {
        font-family: Proxima Nova Condensed;
        font-style: italic;
        font-weight: 900;
        font-size: 22px;
        margin: 0 auto;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.75px;
        color: #C0A35E;
      }
      p {
        font-family: ITC Avant Garde Gothic Std;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #5A5A5A;
        margin-top: 3%;
      }
    }
    .section-drop {
      position: relative;
      width: 100%;
      height: 100%;
      canvas {
        display: block;
        margin: 0 auto;
        box-sizing: border-box;
      }
    }
    .section-2 {
      padding-top: 10%;
      .btn-upload {
        border-radius: 20px;
        width: 100%;
        height: 35px;
        background-color: #c0a35e;
        span:first-child {
          font-family: Proxima Nova Condensed;
          font-style: italic;
          font-weight: 900;
          font-size: 15px;
          line-height: 10px;
          text-align: center;
          letter-spacing: 0.642857px;
          text-transform: uppercase;
          color: #fff;
          text-decoration: none;
          width: 100%;
          height: 25px;
          @media #{$iphone-4-portrait} {
            font-size: 13px;
          }
          @media #{$iphone-5-portrait} {
            font-size: 13px;
          }
          svg {
            margin-right: 10px;
          }
        }
      }
      .img-file {
        margin-top: 3%;
      }
    }
    .save-photo {
      padding: initial;
      padding: 10% 5% 0;
    }
  }
  .clear-padding {
    padding: initial;
    padding-top: 50px;
  }
}
