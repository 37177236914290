.shoppy-pass-container {
  // height: inherit;
  position: relative;
  text-align: center;
  height: 100%;
  width: 100%;
  .section-back {
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    .back-button {
      border-radius: initial;
      width: 100%;
      height: 50px;
      background: #000000;
      span:first-child {
        position: relative;
        font-family: ITC Avant Garde Gothic Std;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 13px;
        text-align: center;
        letter-spacing: 0.441177px;
        text-transform: uppercase;
        color: #ffffff;
        width: 100%;
        height: 36px;
        margin: auto;
        svg {
          position: absolute;
          left: 0;
          width: 30px;
        }
      }
    }
  }
}

.shoppy-content {
  padding-top: 50px;
  height: inherit;
  box-sizing: border-box;
  background: radial-gradient(1049.74px at 50% 50%, rgba(255, 255, 255, 5e-5) 0%, rgba(0, 0, 0, 0.5) 100%), #f5f5f5;
  background-blend-mode: multiply, normal;
  .flip-box {
    background-color: transparent;
    width: 90%;
    height: 230px;
    perspective: 1000px;
    margin: 0 auto;
    margin-top: 15px;
    position: relative;
    @media #{$iphone-5-portrait} {
      height: 180px;
    }

    @media #{$iphone-6-portrait} {
      height: 218px;
    }

    @media #{$motorola-nexus-6-portrait} {
      height: 230px;
    }

    @media #{$iphone-x-portrait} {
      height: 230px;
    }

    @media #{$iphone-6plus-portrait} {
      height: 230px;
    }

    @media #{$iphone-4-portrait} {
      height: 230px;
    }

    @media #{$iphone-4-landscape} {
      height: 230px;
    }

    @media #{$samsung-s6-portrait} {
      height: 240px;
    }

    &.flip {
      .flip-box-inner {
        transform: rotateY(180deg);
      }
    }
    .flip-box-inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.8s;
      transform-style: preserve-3d;
      .flip-box-front,
      .flip-box-back {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        border-radius: 5%;
        box-sizing: border-box;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
        border-radius: 10px;
      }
      .flip-box-front {
        display: grid;
        height: inherit;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 1fr 1fr 0.6fr;
        grid-template-areas:
            "r i"
            "r i"
            "b b";
      }
        .image {
          grid-area: r;
          padding: 0 0 0 12%;
          background: #cba95c;
          border-top-left-radius: 10px;
          display: grid;
          a {
            justify-self: center;
            align-self: center;
          }
          img {
            width: 90px;
            height: 90px;
            justify-self: center;
            align-self: center;
            @media #{$iphone-6-portrait} {
              width: 100px;
              height: 100px;
            }
            @media #{$iphone-x-portrait} {
              width: 100px;
              height: 100px;
            }
          }
        }
        .front-content {
          font-size: 65%;
          font-family: ITC Avant Garde Gothic Std;
          font-style: normal;
          grid-area: i;
          background: #cba95c;
          border-top-right-radius: 10px;
          letter-spacing: 0.415385px;
          color: #000000;
          display: grid;
          .personal {
            justify-self: start;
            align-self: end;
            text-align: left;
            padding-left: 10%;
            p:nth-child(1) {
              font-weight: 900;
            }
            p:nth-child(2) {
              font-weight: 900;
            }
          }
          .shop {
            justify-self: end;
            text-align: right;
            align-self: center;
            padding-right: 5%;
            p, strong {
              justify-self: end;
            }
            strong {
              font-weight: 900;
            }
          }
          p, strong {
            margin-top: 2px;
            @media #{$iphone-6-portrait} {
              margin-top: 5px;
            }
          }
        }
        .front-barcode {
          grid-area: b;
          background-color: #ffffff;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
          display: grid;
          border-collapse: separate !important;
          overflow: hidden;
          img {
            width: 200px;
            align-self: center;
            justify-self: center;
            height: 50px;
            @media #{$iphone-5-portrait} {
              height: 40px;
            }

            @media #{$iphone-6-portrait} {
              height: 50px;
            }

            @media #{$motorola-nexus-6-portrait} {
              height: 50px;
            }

            @media #{$iphone-x-portrait} {
              height: 50px;
            }

            @media #{$iphone-6plus-portrait} {
              height: 50px;
            }

            @media #{$iphone-4-portrait} {
              height: 50px;
            }

            @media #{$samsung-s6-portrait} {
              height: 50px;
            }
          }
          svg {
            align-self: center;
            justify-self: center;
            height: inherit;
          }
          .hide-svg {
            display: none;
          }
        }

      .flip-box-back {
        background-color: #555;
        color: white;
        transform: rotateY(180deg);
        img {
          width: inherit;
          height: inherit;
        }
      }
    }
  }
  .id-card {
    padding-top: 10%;
    background-origin: padding-box;

    .btn-switch {
      padding: initial;
      min-width: 22px;
      height: 22px;
      box-shadow: initial;
      background-color: initial;
      display: block;
      margin-left: 88%;
      box-sizing: border-box;
    }
  }
  .group-button {
    padding: 7% 6% 0 6%;
    box-sizing: border-box;
    @media #{$iphone-5-portrait} {
    }

    @media #{$iphone-6-portrait} {
      padding: 10% 6% 0 6%;
    }

    @media #{$motorola-nexus-6-portrait} {
      padding: 10% 6% 0 6%;
    }

    @media #{$iphone-x-portrait} {
      padding: 10% 6% 0 6%;
    }

    @media #{$iphone-6plus-portrait} {
      padding: 10% 6% 0 6%;
    }

    @media #{$iphone-4-portrait} {
      padding: 10% 6% 0 6%;
    }

    @media #{$samsung-s6-portrait} {
      padding: 10% 6% 0 6%;
    }
    .btn-shoppy-card {
      border-radius: 20px;
      width: 100%;
      height: 35px;
      margin-top: 3%;
      background-color: #c0a35e;
      span:first-child {
        font-family: Proxima Nova Condensed;
        font-style: italic;
        font-weight: 900;
        font-size: 15px;
        line-height: 10px;
        text-align: center;
        letter-spacing: 0.642857px;
        text-transform: uppercase;
        color: #fff;
        text-decoration: none;
        width: 100%;
        height: 25px;
        @media #{$iphone-4-portrait} {
          font-size: 13px;
        }
        @media #{$iphone-5-portrait} {
          font-size: 13px;
        }
      }
    }
    .second-sp {
      background-color: inherit;
      border: 1px solid;
      border-color: #c0a35e;
      box-shadow: initial;
      span:first-child {
        color: #c0a35e;
      }
    }
  }
  .message {
    padding: 0 5%;
    box-sizing: border-box;
    p {
      box-sizing: border-box;
      font-family: ITC Avant Garde Gothic Std;
      font-style: normal;
      font-weight: 500;
      font-size: 9px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.415385px;
      color: #4a4a4a;
      @media #{$iphone-6-portrait} {
        font-size: 10px;
      }
      span {
        text-decoration: none;
        color: #c0a35e;
      }
    }
    @media #{$iphone-5-portrait} {
      padding-top: 5%;
    }
    @media #{$iphone-6-portrait} {
      padding-top: 10%;
    }
    @media #{$iphone-x-portrait} {
      padding-top: 30%;
    }
    @media #{$iphone-6plus-portrait} {
      padding-top: 20%;
    }
    @media #{$samsung-s6-portrait} {
      padding-top: 10%;
    }
  }
}
