body {
  height: 100%;
  touch-action: manipulation;
  .App {
    height: 100%;
    .login-container {
      height: 100%;
    }
    &.chrome {
    }
  }
}
