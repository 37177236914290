.angebote.guest-container {
  .guest-screen {
    .section-2 {
      .carousel-container.user {
        background: radial-gradient(388.67px at 49.66% 50%, rgba(255, 255, 255, 3e-05) 0%, rgba(0, 0, 0, 0.3) 100%), #4a90e2;
      }
    }
  }
}

.list-screen {
  .activated-brown {
    background-color: #C0A35E;
  }
  .activated-blue {
    background-color: #C7D319;
  }
  .list-item-title {
    span {
      font-family: ITC Avant Garde Gothic Std;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      letter-spacing: 0.46875px;
      color: #4a4a4a;
    }
  }
  .text-color {
    span {
      color: #ffffff;
    }
  }
}
