.main-page {
  touch-action: manipulation;
  .back-button {
    z-index: 999;
    a {
      display: none;
    }
  }
  .main-screen {
    padding: 50px 0;
    @media #{$iphone-5-portrait} {
      padding: 50px 0;
    }

    @media #{$iphone-6-portrait} {
      padding: 50px 0;
    }

    @media #{$motorola-nexus-6-portrait} {
      padding: 50px 0;
    }

    @media #{$iphone-x-portrait} {
      padding: 50px 0 70px 0;
    }

    @media #{$iphone-6plus-portrait} {
      padding: 50px 0;
    }

    @media #{$iphone-4-portrait} {
      padding: 50px 0;
    }

    @media #{$samsung-s6-portrait} {
      padding: 50px 0;
    }
  }
}

.my-page-container {
  overflow: hidden;
  min-height: -webkit-fill-available;
  .preloader-container {
    min-height: inherit;
  }
  .my-page-screen {
    .my-page-tabs {
      height: 55px;
      display: flex;
      flex-direction: row;
      background: #1e1e1e;
      padding: 8px;
      box-sizing: border-box;
      a {
        flex: 1;
        text-decoration: none;
        &:first-child {
          border-right: 1px solid #3b3b3b;
        }
        .tab-inside {
          flex: 1;
          display: flex;
          justify-content: center;
          .title {
            color: #ffffff;
            font-size: 11px;
            line-height: 17px;
            padding-left: 5px;
            text-transform: uppercase;
          }
          .icon {
            justify-content: center;
            flex-direction: column;
            text-align: center;
            display: flex;
            padding-right: 5px;
            img {
              width: 27px;
            }
          }

          .c100 {
            &.small {
              font-size: 34px;
            }
            &.dark:after {
              background-color: #1e1e1e;
            }
            &.dark {
              .bar {
                border-color: #c0a35e !important;
              }
              .fill {
                border-color: #c0a35e !important;
              }
            }
            & > span {
              line-height: 1.2em;
              top: 9px;
            }
          }
        }
      }
    }
    .section-1 {
      padding: 30px 20px 20px;
      background: radial-gradient(757.3px at 50% 50%, rgba(255, 255, 255, 0.0001) 0%, #000000 100%), #c0a35e;
      // background-blend-mode: multiply, normal;
      font-family: "ITC Avant Garde Gothic Std";
      color: #ffffff;
      .sample-card {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        img {
          width: 100px;
          height: 60px;
        }
      }
      h1 {
        font-family: Proxima Nova Condensed;
        font-style: italic;
        font-weight: 900;
        font-size: 26px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.8125px;
        margin-bottom: 12px;
      }
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.2px;
        margin-bottom: 5px;
        strong {
          font-weight: 700;
        }
      }
      .btn-sign-in {
        margin: 30px auto auto;
        border-radius: 20px;
        width: 100%;
        height: 35px;
        background-color: #ffffff;
        span:first-child {
          font-family: Proxima Nova Condensed;
          font-style: italic;
          font-weight: 900;
          font-size: 15px;
          line-height: 10px;
          text-align: center;
          letter-spacing: 0.642857px;
          text-transform: uppercase;
          color: #000000;
          text-decoration: none;
          width: 100%;
          height: 25px;
          @media #{$iphone-4-portrait} {
            font-size: 13px;
          }
        }
      }

      .btn-sign-up {
        margin: 15px auto auto;
        border-radius: 20px;
        width: 100%;
        height: 35px;
        background-color: transparent;
        border: 1px solid #fff;
        box-shadow: none;
        span:first-child {
          font-family: "Proxima Nova Condensed";
          font-style: italic;
          font-weight: 900;
          font-size: 15px;
          line-height: 10px;
          text-align: center;
          letter-spacing: 0.642857px;
          text-transform: uppercase;
          color: #fff;
          text-decoration: none;
          width: 100%;
          height: 25px;
          @media #{$iphone-4-portrait} {
            font-size: 13px;
          }
          @media #{$iphone-5-portrait} {
            font-size: 13px;
          }
        }
      }
      .text-warning {
        font-size: 10px;
        display: block;
        text-align: center;
        padding-top: 12px;
      }
    }
    .section-2 {
      .item-content:nth-child(2) {
        background: linear-gradient(359.91deg, #b29856 1.72%, #e7d7a1 24.2%, #b18f3e 99.58%);
        h2 {
          // font-size: 21px;
          // @media #{$iphone-5-portrait} {
          //   font-size: 18px;
          // }
          // @media #{$iphone-6-portrait} {
          //   font-size: 22px;
          // }
          // @media #{$iphone-6plus-portrait} {
          //   font-size: 24px;
          // }
          // @media #{$iphone-4-portrait} {
          //   font-size: 18px;
          // }
        }
      }
    }
    .section-3 {
    }

    .section-4 {
    }
    .card {
      .card-media {
        .responsive-img {
          width: 100%;
          height: auto;
        }
      }
      .card-content {
        padding: 15px 15px 5px;
        h5 {
          font-size: 12px;
          color: #c0a35e;
          padding-bottom: 10px;
        }
        .card-paragraph {
          font-size: 12px;
          color: #5a5a5a;
          line-height: 20px;
        }
      }
      .card-action {
        padding: 0 15px 15px;
        a {
          font-size: 12px;
          color: #c0a35e;
          text-decoration: none;
          position: relative;
          padding-left: 20px;
          svg {
            fill: #c0a35e;
            width: 14px;
            position: absolute;
            left: 0;
          }
        }
        .btn-all-news {
          margin: auto;
          border-radius: 20px;
          width: 100%;
          height: 35px;
          background-color: #c0a35e;
          margin: 25px 0;
          span:first-child {
            font-family: Proxima Nova Condensed;
            font-style: italic;
            font-weight: 900;
            font-size: 15px;
            line-height: 10px;
            text-align: center;
            letter-spacing: 0.642857px;
            text-transform: uppercase;
            color: #fff;
            text-decoration: none;
            width: 100%;
            height: 25px;
            @media #{$iphone-4-portrait} {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
