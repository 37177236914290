.congras-screen.main-screen {
  padding: 0;
  .congras-screen {
    min-height: -webkit-fill-available;
    background: radial-gradient(1180.43px at 50% 50%, rgba(255, 255, 255, 0.0001) 0%, #000000 100%), #c0a35e;
    background-image: url("../../assets/img/multi-coin-bg.svg");
    background-size: 150%;
    background-position: center center;
    width: 100%;
    .congras-gift-bg {
      min-height: -webkit-fill-available;
      padding-top: 30%;
      display: flex;
      flex-direction: column;
      @media #{$iphone-5-portrait} {
        padding-top: 10%;
      }
      @media #{$iphone-6-portrait} {
        padding-top: 15%;
      }

      @media #{$motorola-nexus-6-portrait} {
        padding-top: 25%;
      }

      @media #{$iphone-x-portrait} {
        padding-top: 30%;
      }

      @media #{$iphone-6plus-portrait} {
        padding-top: 15%;
      }

      @media #{$iphone-4-portrait} {
      }

      @media #{$iphone-4-landscape} {
      }

      @media #{$samsung-s6-portrait} {
        padding-top: 30%;
      }
      img {
        margin: 0 auto;
      }
      h1 {
        font-family: Proxima Nova Condensed;
        font-style: italic;
        font-weight: 900;
        font-size: 100px;
        line-height: 51px;
        text-align: center;
        color: #ffffff;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        margin-bottom: 10px;
        @media #{$iphone-5-portrait} {
          font-size: 60px;
        }
        @media #{$iphone-6-portrait} {
          font-size: 100px;
          line-height: 80px;
        }

        @media #{$motorola-nexus-6-portrait} {
        }

        @media #{$iphone-x-portrait} {
          font-size: 100px;
          line-height: 80px;
        }

        @media #{$iphone-6plus-portrait} {
          font-size: 100px;
          line-height: 80px;
        }

        @media #{$iphone-4-portrait} {
        }

        @media #{$iphone-4-landscape} {
        }

        @media #{$samsung-s6-portrait} {
          font-size: 100px;
          line-height: 80px;
        }
      }
      h2 {
        font-family: Proxima Nova Condensed;
        font-style: italic;
        font-weight: 900;
        font-size: 48px;
        line-height: 51px;
        text-align: center;
        color: #ffffff;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        margin-bottom: 45px;
        @media #{$iphone-5-portrait} {
          font-size: 38px;
          line-height: normal;
          margin-bottom: 25px;
        }
        @media #{$iphone-6-portrait} {
          font-size: 48px;
          line-height: 51px;
          margin-bottom: 45px;
        }

        @media #{$motorola-nexus-6-portrait} {
          font-size: 48px;
          line-height: 51px;
          margin-bottom: 45px;
        }

        @media #{$iphone-x-portrait} {
          font-size: 48px;
          line-height: 51px;
          margin-bottom: 45px;
        }

        @media #{$iphone-6plus-portrait} {
          font-size: 48px;
          line-height: 51px;
          margin-bottom: 45px;
        }

        @media #{$iphone-4-portrait} {
        }

        @media #{$iphone-4-landscape} {
        }

        @media #{$samsung-s6-portrait} {
          font-size: 48px;
          line-height: 51px;
          margin-bottom: 45px;
        }
      }
      h6 {
        font-family: ITC Avant Garde Gothic Std;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 21px;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
        margin-bottom: 45px;
        @media #{$iphone-5-portrait} {
          margin-bottom: 20px;
        }
        @media #{$iphone-6-portrait} {
          margin-bottom: 20px;
        }

        @media #{$motorola-nexus-6-portrait} {
          margin-bottom: 20px;
        }

        @media #{$iphone-x-portrait} {
          margin-bottom: 20px;
        }

        @media #{$iphone-6plus-portrait} {
          margin-bottom: 20px;
        }

        @media #{$iphone-4-portrait} {
        }

        @media #{$iphone-4-landscape} {
        }

        @media #{$samsung-s6-portrait} {
          margin-bottom: 20px;
        }
      }
    }
    .btn-points {
      position: absolute;
      bottom: 13%;
      margin: auto;
      left: 0;
      right: 0;
      border-radius: 20px;
      width: 80%;
      height: 35px;
      background-color: #ffffff;
      span:first-child {
        font-family: Proxima Nova Condensed;
        font-style: italic;
        font-weight: 900;
        font-size: 15px;
        line-height: 10px;
        text-align: center;
        letter-spacing: 0.642857px;
        text-transform: uppercase;
        color: #000000;
        text-decoration: none;
        width: 100%;
        height: 25px;
        @media #{$iphone-4-portrait} {
          font-size: 13px;
        }
      }
    }
  }
}
