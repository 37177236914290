.back-button {
  position: fixed;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 92.5%;
  background: #000000;
  height: 50px;
  z-index: 999;
  p {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    font-family: ITC Avant Garde Gothic Std;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.441177px;
    text-transform: uppercase;
    color: #FFFFFF;
    width: 280px;
    height: 13px;
    margin: auto;
  }

  a:before {
    content: "";
    display: inline-block;
    background: url('../../../assets/img/back-button.png') no-repeat;
    width: 9px;
    height: 15px;
    position: absolute;
    left: 4.53%;
    right: 93.07%;
    top: 35.4%;
    bottom: 95.05%;
  }
  i.icon-back {
    width: 55px;
    height: 50px;
    display: block;
    &:before {
      content: "";
      display: inline-block;
      background: url('../../../assets/img/back-button.png') no-repeat;
      width: 15px;
      height: 15px;
      position: absolute;
      left: 4.53%;
      right: 93.07%;
      top: 35.4%;
      bottom: 95.05%;
    }
  }
  
}
