// @import './select.scss';
.register-page {
  position: relative;
  text-align: center;
  height: 100%;
  width: 100%;
  form {
    height: 100%;
    .section-back {
      position: fixed;
      top: 0;
      z-index: 99;
      width: 100%;
      .back-button {
        border-radius: initial;
        width: 100%;
        height: 50px;
        background: #000000;
        span:first-child {
          position: relative;
          font-family: ITC Avant Garde Gothic Std;
          font-style: normal;
          font-weight: bold;
          font-size: 15px;
          line-height: 13px;
          text-align: center;
          letter-spacing: 0.441177px;
          text-transform: uppercase;
          color: #ffffff;
          width: 100%;
          height: 30px;
          margin: auto;
          svg {
            position: absolute;
            left: 0;
            width: 30px;
          }
        }
      }
    }

    .section-content {
      padding-top: 50px;
      position: relative;
      height: auto;
      h2 {
        width: 100%;
        padding-top: 10%;
        font-size: 24px;
        font-family: Proxima Nova Condensed;
        font-style: italic;
        font-weight: 900;
        letter-spacing: 0.75px;
        color: #c7d319;
        @media #{$iphone-6-portrait} {
          font-size: 26px;
        }
        @media #{$iphone-6plus-portrait} {
          font-size: 30px;
        }
      }
      .brow-color {
        color: #c0a35e;
      }
      p {
        width: 100%;
        margin: 0 auto;
        margin-top: 5%;
        font-weight: 500;
        letter-spacing: 0.3px;
        font-size: 13px;
        line-height: 20px;
        font-family: ITC Avant Garde Gothic Std;
        font-style: normal;
        color: #5a5a5a;
        @media #{$iphone-6-portrait} {
          width: 86%;
        }
        @media #{$iphone-6plus-portrait} {
          width: 76%;
        }
      }

      .input-fields {
        width: 100%;
        margin-top: 7%;
        p {
          width: 100%;
          margin-top: 20px;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 10px;
          letter-spacing: 0.382353px;
          text-align: left;
          padding-left: 5%;
          box-sizing: border-box;
          color: #9b9b9b;
        }
        span {
          display: block;
          width: 100%;
          margin: 0 auto;
          color: red;
          font-size: 13px;
          margin-top: 2px;
          font-family: ITC Avant Garde Gothic Std;
        }
        .input-id-login {
          width: 90%;
          height: 40px;
          margin: 0 auto;
          margin-top: 10px;
          font-size: 13px;
          font-family: ITC Avant Garde Gothic Std;
          background: #f5f5f5;
          border: 0px solid;
          color: #000000;
          box-sizing: border-box;
          padding-left: 10px;
        }
        .input-id-login-phone {
          width: 100%;
          height: 40px;
          font-size: 13px;
          background: #f5f5f5;
          border: 0px solid;
          border-radius: initial;
          color: #000000;
          font-family: ITC Avant Garde Gothic Std;
          box-sizing: border-box;
          padding-left: 10px;
        }
        .input-id-login-phone-flag {
          border: initial;
        }
        .selected-flag {
          display: none;
        }

        .disable {
          // background: #c9c8c4;
          color: #000;
        }
        .error {
          border: 1px solid;
          border-color: red;
        }
        input[name="gender"]::-webkit-calendar-picker-indicator {
          width: 20px;
          height: 15px;
          opacity: 0.7;
          margin-right: 10px;
          box-sizing: border-box;
          color: #9b9b9b;
          opacity: 1;
        }

        .birthday-fields {
          width: 90%;
          margin: 0 auto;
          overflow: hidden;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .render-birthday-input{
            width: 30%;
            display: flex;
            flex-direction: column;
          }
          .react-numeric-input {
            width: 30%;
            display: initial;
            margin: initial;
            position: relative;
            b:first-child {
              position: absolute;
              right: 2px;
              width: 2.26ex;
              border-color: rgba(0, 0, 0, 0.1);
              border-style: solid;
              text-align: center;
              cursor: default;
              transition: all 0.1s ease 0s;
              background: rgba(0, 0, 0, 0.1);
              box-shadow: rgba(0, 0, 0, 0.1) -1px -1px 3px inset, rgba(255, 255, 255, 0.7) 1px 1px 3px inset;
              top: 2px;
              bottom: 50%;
              border-radius: 2px 2px 0px 0px;
              border-width: 1px 1px 0px;
              i {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 0px;
                height: 0px;
                border-width: 0px 0.6ex 0.6ex;
                border-color: transparent transparent rgba(0, 0, 0, 0.7);
                border-style: solid;
                margin: -0.3ex 0px 0px -0.56ex;
              }
            }
          }
          .date {
            margin-top: 10px;
            height: 40px;
            font-weight: normal;
            font-size: 14px;
            background-color: #f5f5f5;
            color: #000000;
            border: 0px solid;
            text-align: center;
            box-sizing: border-box;
            @media #{$iphone-6-portrait} {
              height: 50px;
            }
          }
          .disable {
            background: #c9c8c4;
            color: #807e77;
          }
          .error {
            border: 1px solid;
            border-color: red;
          }
          input[type="number"]::-webkit-inner-spin-button {
            width: 20px;
            height: 30px;
            opacity: 0.7;
            margin-right: 10px;
            box-sizing: border-box;
            color: #9b9b9b;
          }
          input:nth-child(2) {
            margin: 10px 5%;
          }
        }
      }
      .field-email {
        margin-top: 10%;
      }
      .code-input {
        margin-top: 5%;
        p {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 20px;
          text-align: center;
          letter-spacing: 0.3px;
          color: #9b9b9b;
          margin-top: 10px;
          width: 70%;
        }

        .resend-code {
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0.3px;
          text-decoration: underline;
          color: #c7d319;
          border: 0px solid #ffffff;
          background: transparent;
          margin-top: initial;
        }
      }
      .submits {
        padding: 22% 0;
        .account-submit {
          width: 90%;
          left: 5%;
          top: 70%;
          display: block;
          margin: 0 auto;
          background: #c7d319;
          box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
          border-radius: 60px;
          border: 0px solid;
          height: 30px;
          font-family: Proxima Nova Condensed;
          font-style: italic;
          font-weight: 900;
          font-size: 15px;
          line-height: 10px;
          text-align: center;
          letter-spacing: 0.642857px;
          text-transform: uppercase;
          color: #ffffff;
          &:disabled {
            box-shadow: none;
          }
        }
        .brow-background-color {
          background: #c0a35e;
        }
      }
      .submit-email {
        padding: 20% 0 15% 0;
      }
      .submit-email-phone {
        padding: 12% 0 10% 0;
      }
      .submit-code-input {
        padding: 10% 0;
      }
      .login-verify {
        margin-top: 10%;
        input {
          box-sizing: border-box;
          width: 30%;
          height: 60px;
          font-family: ITC Avant Garde Gothic Std;
          font-weight: normal;
          font-size: 14px;
          background-color: #f5f5f5;
          color: #9b9b9b;
          border: 0px solid;
          margin-left: 4px;
          text-align: center;
        }
        input[type="number"]::-webkit-inner-spin-button {
          width: 20px;
          height: 30px;
          opacity: 0.7;
          margin-right: 10px;
          box-sizing: border-box;
          color: #9b9b9b;
        }
      }
      .checkbox-verify {
        padding: 0 20px 0 16px;
        display: flex;
        align-items: flex-start;
        span {
          padding-left: 0;
          float: left;
          &.error {
            path {
              fill: #ff0000;
            }
          }
        }
        label {
          letter-spacing: 0.3px;
          font-size: 13px;
          font-family: ITC Avant Garde Gothic Std;
          font-style: normal;
          color: #5a5a5a;
          text-align: start;
          p {
            margin: 12px 0 0 0;
            width: 100%;
          }
        }
        .error-message-checkbox {
          color: #ff0000;
          margin: 0;
        }
      }
    }
  }
}

select {
  /* styling */
  background-color: white;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, #aaaaaa 50%),
    linear-gradient(135deg, #aaaaaa 50%, transparent 53%) !important;
  background-position: calc(100% - 20px) calc(1em + 4px), calc(100% - 15px) calc(1em + 4px), 100% 0 !important;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em !important;
  background-repeat: no-repeat !important;
  &.gender-default {
    // color: rgb(117, 117, 117) !important;
    color: #9b9b9b !important;
  }
  option {
    color: #000000;
  }
}

// .select-grey {
//   color: red !important;
// }
