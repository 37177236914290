.carousel-container {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  &.public {
    background: radial-gradient(757.3px at 50% 50%, rgba(255, 255, 255, 0.0001) 0%, #000000 100%), #c7d319 !important;
  }
  h1 {
    font-family: "ITC Avant Garde Gothic Std";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.464286px;
    text-transform: uppercase;
    height: 43px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
  }
  .btn-all {
    .carousel-view {
      border: 1px solid #ffffff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      border-radius: 12px;
      display: -webkit-flex; //_ Safari _
      display: flex !important;
      overflow: hidden;
      flex-direction: column;
      -webkit-flex-direction: column;
      text-align: center;
      font-family: Proxima Nova Condensed;
      font-style: italic;
      font-weight: 900;
      font-size: 24px;
      display: flex;
      justify-content: center;
      color: #ffffff;
      text-transform: uppercase;
      height: 205px;
      margin: 20px 1px 20px 15px;
      @media #{$iphone-5-portrait} {
        height: 205px;
        margin: 20px 1px 20px 15px;
      }

      @media #{$iphone-6-portrait} {
        height: 201px;
        margin: 15px 1px 20px 15px;
      }

      @media #{$motorola-nexus-6-portrait} {
        height: 201px;
        margin: 15px 1px 20px 15px;
      }

      @media #{$iphone-x-portrait} {
        height: 201px;
        margin: 15px 1px 20px 15px;
      }

      @media #{$iphone-6plus-portrait} {
        height: 201px;
        margin: 15px 1px 20px 15px;
      }

      @media #{$iphone-4-portrait} {
        height: 201px;
        margin: 15px 1px 20px 15px;
      }

      @media #{$samsung-s6-portrait} {
        height: 190px;
        margin: 10px 0 10px 10px;
      }
    }
  }
}
