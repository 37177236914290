// @import "../welcome/welcome.scss";
.home.welcome-container {
  padding: 20px;
  box-sizing: border-box;
  .welcome-screen {
    .welcome-body {
      @media #{$iphone-5-portrait} {
        margin-top: 30px;
      }

      @media #{$iphone-6-portrait} {
        margin-top: 80px;
      }

      @media #{$iphone-x-portrait} {
        margin-top: 110px;
      }

      @media #{$motorola-nexus-6-portrait} {}

      

      @media #{$iphone-6plus-portrait} {}

      @media #{$iphone-4-portrait} {}

      @media #{$iphone-4-landscape} {}

      @media #{$samsung-s6-portrait} {}

      p {
        &:nth-child(1) {
          
        }
        &:nth-child(3) {
          font-family: ITC Avant Garde Gothic Std;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0.5px;
          @media #{$iphone-5-portrait} {
          }
          @media #{$iphone-x-portrait} {
          }

          @media #{$iphone-6-portrait} {
          }

          @media #{$iphone-6plus-portrait} {
          }
          @media #{$iphone-4-portrait} {
            font-size: 11px;
          }
          @media #{$motorola-nexus-6-portrait} {
          }
        }
        &:nth-child(4),
        &:nth-child(5) {
          font-family: ITC Avant Garde Gothic Std;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0.5px;
          @media #{$iphone-5-portrait} {
          }

          @media #{$iphone-6-portrait} {
          }

          @media #{$iphone-x-portrait} {
            font-family: ITC Avant Garde Gothic Std;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0.5px;
          }

          @media #{$iphone-6plus-portrait} {
          }

          @media #{$iphone-4-portrait} {
          }

          .text-link {
            font-family: "ITC Avant Garde Gothic Std";
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align: center;
            letter-spacing: 0.54px;
            // text-decoration: none;
            color: #fff;
          }
        }
        &:nth-child(4) {
          margin-top: 25px;
          @media #{$iphone-5-portrait} {
            margin-top: 15px;
          }
          @media #{$motorola-nexus-6-portrait} {
          }
        }
      }
    }
    .btn-welcome {
      margin-top: 25px;
      @media #{$iphone-5-portrait} {
      }
      @media #{$motorola-nexus-6-portrait} {
      }
      @media #{$iphone-x-portrait} {
      }

      @media #{$iphone-6-portrait} {
      }
    }
    .btn-signup {
      margin-top: 15px;
      border-radius: 20px;
      width: 100%;
      height: 35px;
      background-color: transparent;
      border: 1px solid #fff;
      box-shadow: none;
      @media #{$iphone-5-portrait} {
      }
      @media #{$iphone-6-portrait} {
      }
      @media #{$iphone-x-portrait} {
      }

      @media #{$iphone-6plus-portrait} {
      }
      @media #{$iphone-4-portrait} {
      }
      @media #{$iphone-4-landscape} {
      }
      @media #{$motorola-nexus-6-portrait} {
      }
      span:first-child {
        font-family: Proxima Nova Condensed;
        font-style: italic;
        font-weight: 900;
        font-size: 15px;
        line-height: 10px;
        text-align: center;
        letter-spacing: 0.642857px;
        text-transform: uppercase;
        color: #fff;
        text-decoration: none;
        width: 100%;
        height: 25px;

        @media #{$iphone-5-portrait} {
          font-size: 12px;
        }

        @media #{$iphone-6-portrait} {
          font-size: 15px;
        }
       
        @media #{$iphone-4-portrait} {
          font-size: 12px;
        }
      }
    }
    .btn-guest {
      font-family: ITC Avant Garde Gothic Std;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.5px;
      width: 100%;
      height: 35px;
      background-color: transparent;
      box-shadow: none;
      text-transform: initial;
      color: #fff;
      margin-top: 25px;
      text-decoration: underline;
      @media #{$iphone-5-portrait} {
        margin-top: 15px;
      }
      @media #{$iphone-6-portrait} {
      }
      @media #{$iphone-x-portrait} {
      }

      @media #{$iphone-6plus-portrait} {
      }
      @media #{$iphone-4-portrait} {
        margin-top: 10px;
      }
      @media #{$motorola-nexus-6-portrait} {
      }
    }
  }
}
