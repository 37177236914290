.vorteile-container {
  overflow: hidden;
  .back-button {
    background-color: #f5f5f5;
    p {
      color: #000;
    }
    a {
      width: 45px;
      height: 45px;
      display: block;
      &:before {
        content: "";
        display: inline-block;
        background: url("../../assets/img/back-button-white.png") no-repeat;
        width: 9px;
        height: 15px;
        position: absolute;
        left: 4.53%;
        right: 93.07%;
        top: 35.4%;
        bottom: 95.05%;
      }
    }
  }
  .screen-container {
    padding: 70px 15px 15px;
    font-family: "Proxima Nova Condensed";
    h1 {
      font-size: 34px;
      line-height: 40px;
      letter-spacing: 0.894737px;
      font-style: italic;
      text-align: center;
      font-weight: 900;
    }
    h2 {
      font-weight: 900;
      font-size: 26px;
      line-height: 30px;
      letter-spacing: 0.75px;
      font-style: italic;
      text-align: center;
      font-weight: 900;
    }
    ul {
      color: #5a5a5a;
      list-style-image: url("../../assets/img/stick-icon.png");
      padding-left: 25px;
      li {
        font-family: ITC Avant Garde Gothic Std;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.3px;
        margin: 25px 0;
        strong {
          font-weight: 700;
        }
      }
    }
    .btn-vortelie {
      // position: absolute;
      // bottom: 15%;
      // margin: auto;
      // left: 0;
      // right: 0;
      border-radius: 20px;
      width: 100%;
      height: 35px;
      background-color: #c0a35e;
      // @media #{$iphone-6-portrait} {
      //   bottom: 24%;
      // }
      // @media #{$iphone-x-portrait} {
      //   bottom: 28%;
      // }

      // @media #{$iphone-6plus-portrait} {
      //   bottom: 15%;
      // }
      // @media #{$iphone-4-portrait} {
      //   width: 80%;
      //   bottom: 16%;
      // }
      // @media #{$iphone-4-landscape} {
      //   bottom: 11%;
      // }
      span:first-child {
        font-family: Proxima Nova Condensed;
        font-style: italic;
        font-weight: 900;
        font-size: 15px;
        line-height: 10px;
        text-align: center;
        letter-spacing: 0.642857px;
        text-transform: uppercase;
        color: #fff;
        text-decoration: none;
        width: 100%;
        height: 25px;
        @media #{$iphone-4-portrait} {
          font-size: 13px;
        }
        @media #{$iphone-5-portrait} {
          font-size: 13px;
        }
      }
    }
  }
}
