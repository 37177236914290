.ruler {
  position: relative;
  width: 82%;
  margin: 20px auto;
  margin-bottom: 2%;
  height: 14px;
  font-family: ITC Avant Garde Gothic Std;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 21px;
}
.ruler .cm,
.ruler .mm {
  position: absolute;
  border-left: 2px solid #E2E2E2;
  height: 8px;
  width: 10%;
}
.ruler .cm:after {
  position: absolute;
  bottom: 2px;
}
.ruler .cm:nth-of-type(1) {
  left: 0%;
  border: 0px;
  border-left: 2px solid #E2E2E2;
}
// .ruler .cm:nth-of-type(1):after {
  // content: "0";
// }
.ruler .cm:nth-of-type(2) {
  left: 10%;
}
.ruler .cm:nth-of-type(3) {
  left: 20%;
}
.ruler .cm:nth-of-type(4) {
  left: 30%;
}
.ruler .cm:nth-of-type(5) {
  left: 40%;
}
.ruler .cm:nth-of-type(6) {
  left: 50%;
}
.ruler .cm:nth-of-type(7) {
  left: 60%;
}
.ruler .cm:nth-of-type(8) {
  left: 70%;
}
.ruler .cm:nth-of-type(9) {
  left: 80%;
}
.ruler .cm:nth-of-type(10) {
  left: 90%;
}
.ruler .cm:nth-of-type(11) {
  border: 0px;
  left: 100%;
  border-left: 2px solid #E2E2E2;
}
// .ruler .cm:nth-of-type(11):after {
//   content: "100";
// }
#mentionme{  
  text-align:center;
  margin-top:10%;
}
