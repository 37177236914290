.error-page {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: normal;
  text-transform: uppercase;
  code {
    font-size: 13px;
    text-transform: initial;
  }
}