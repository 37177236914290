.main-page {
  .back-button {
    z-index: 999;
    a {
      display: none;
    }
  }
  .main-screen {
    padding: 50px 0;
    height: auto;
    min-height: 100%;
    @media #{$iphone-5-portrait} {
      padding: 50px 0;
    }

    @media #{$iphone-6-portrait} {
      padding: 50px 0;
    }

    @media #{$motorola-nexus-6-portrait} {
      padding: 50px 0;
    }

    @media #{$iphone-x-portrait} {
      padding: 50px 0 70px 0;
    }

    @media #{$iphone-6plus-portrait} {
      padding: 50px 0;
    }

    @media #{$iphone-4-portrait} {
      padding: 50px 0;
    }

    @media #{$samsung-s6-portrait} {
      padding: 50px 0;
    }
  }
}

.more-container {
  overflow: hidden;
  .more-screen {
    // padding: 0 15px;
    background: #FAFAFA;
    nav {
      padding-bottom: initial;
    }
    .list-item-title {
      span {
        font-weight: 700;
      }
    }
    .list-header-title {
      display: flex;
      justify-content: center;
      span {
        font-family: ITC Avant Garde Gothic Std;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.464286px;
        text-transform: uppercase;
      }
    }
    .list-wrapper {
      padding: 0 15px;
      div:last-child {
        border-bottom: initial;
      }
    }
    
  }
}
